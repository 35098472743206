import React, { HTMLAttributes } from 'react';
import { cn } from '~/lib/utils';

interface DragHandleProps extends HTMLAttributes<HTMLDivElement> {
  containerClassName?: string;
  classNames?: {
    img?: string; // Optional chaining for flexibility
  };
}

export default function DragHandle({
  containerClassName = '',
  classNames = {},
  ...props
}: DragHandleProps) {
  return (
    <div
      {...props}
      className={cn(containerClassName, 'inline-block')}
      data-testid="drag-handle"
    >
      <img
        src="/icons/drag-dots.svg"
        className={cn(classNames.img, 'inline align-middle')}
        alt="Drag handle"
        data-testid="grip-vertical-icon"
      />
    </div>
  );
}
