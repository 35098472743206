import {
  AnalyticsSchema,
  KpiSchema,
  PageHitSchema,
  ProjectSchema,
  TransactionSchema,
  WalletSchema,
} from '@formo/types';
import { z } from 'zod';

import { REGEX } from '../constants';
import { filterRequest } from './common';

export const getProjectsOfTeamRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
  query: z.object({
    isActive: z.boolean().optional(),
    ...filterRequest.shape,
  }),
});
export type GetProjectsOfTeamRequest = z.infer<typeof getProjectsOfTeamRequest>;
export type GetProjectsOfTeamResponse = (Pick<
  ProjectSchema,
  'id' | 'title' | 'teamId' | 'creatorId'
> & {
  team: {
    teamName: string;
  };
  creator: {
    name: string;
  };
  createdAt: Date;
  updatedAt: Date;
})[];

export const getProjectByIdRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetProjectByIdRequest = z.infer<typeof getProjectByIdRequest>;
export type GetProjectByIdResponse = ProjectSchema;

export const createProjectRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
  body: z.object({
    id: z.string().nullish(),
    projectName: z.string().min(1, 'Project name is required'),
    allowedUrls: z
      .array(
        z
          .string()
          .min(1, 'Project URL is required')
          .refine(
            (value) => REGEX.LINK.test(value ?? ''),
            'Project URL should be a valid link',
          )
          .transform((link) => {
            if (!link.startsWith('http://') && !link.startsWith('https://')) {
              link = `https://${link}`;
            }
            return link;
          }),
      )
      .min(1, 'Allow URLs is required'),
  }),
});
export type CreateProjectRequest = z.infer<typeof createProjectRequest>;
export type CreateProjectResponse = ProjectSchema;

export const activateProjectRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type ActivateProjectRequest = z.infer<typeof activateProjectRequest>;
export type ActivateProjectResponse = ProjectSchema;

export const verifyProjectRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type VerifyProjectRequest = z.infer<typeof verifyProjectRequest>;
export type VerifyProjectResponse = string;

export const updateProjectRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
  body: z
    .object({
      projectName: z.string().min(1, 'Project name is required'),
      allowedUrls: z
        .array(
          z
            .string()
            .refine(
              (value) => REGEX.LINK.test(value ?? ''),
              'Project URL should be a valid link',
            )
            .transform((link) => {
              if (!link.startsWith('http://') && !link.startsWith('https://')) {
                link = `https://${link}`;
              }
              return link;
            }),
        )
        .min(1, 'Allow URLs is required'),
      settings: z.record(z.any()).optional(),
    })
    .strip(),
});
export type UpdateProjectRequest = z.infer<typeof updateProjectRequest>;
export type UpdateProjectResponse = ProjectSchema;

export const refreshProjectRequest = z.object({
  body: z.object({
    token: z.string().min(1, 'Token is required'),
  }),
});
export type RefreshProjectRequest = z.infer<typeof refreshProjectRequest>;
export type RefreshProjectResponse = {
  token: string;
};

export const deleteProjectRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type DeleteProjectRequest = z.infer<typeof deleteProjectRequest>;
export type DeleteProjectResponse = null;

export const getKpisRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetKpisRequest = z.infer<typeof getKpisRequest>;
export type GetKpisResponse = KpiSchema[];

export const getWalletsRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetWalletsRequest = z.infer<typeof getWalletsRequest>;
export type GetWalletsResponse = WalletSchema[];

export const getTransactionsRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetTransactionsRequest = z.infer<typeof getTransactionsRequest>;
export type GetTransactionsResponse = TransactionSchema[];

export const getPageHitsRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetPageHitsRequest = z.infer<typeof getPageHitsRequest>;
export type GetPageHitsResponse = PageHitSchema[];

export const getDevicesRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetDevicesRequest = z.infer<typeof getDevicesRequest>;
export type GetDevicesResponse = AnalyticsSchema[];

export const getLocationsRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetLocationsRequest = z.infer<typeof getLocationsRequest>;
export type GetLocationsResponse = AnalyticsSchema[];

export const getReferrersRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetReferrersRequest = z.infer<typeof getReferrersRequest>;
export type GetReferrersResponse = AnalyticsSchema[];

export const getPagesRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    projectId: z.string().min(1, 'Project ID is required'),
  }),
});
export type GetPagesRequest = z.infer<typeof getPagesRequest>;
export type GetPagesResponse = AnalyticsSchema[];
