import { FC, HTMLAttributes } from 'react';

type ParagraphProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Paragraph: FC<ParagraphProps> = ({
  size = 41,
  shouldBlend = false,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1903_27115)">
        <rect x="5" y="4.375" width="30" height="30" rx="6" fill="#FEE2E2" />
        <rect
          x="5.5"
          y="4.875"
          width="29"
          height="29"
          rx="5.5"
          stroke="#C42222"
          stroke-opacity="0.5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.2762 12.0764C20.2762 11.5241 20.724 11.0764 21.2762 11.0764H28.3877C28.94 11.0764 29.3877 11.5241 29.3877 12.0764C29.3877 12.6287 28.94 13.0764 28.3877 13.0764H21.2762C20.724 13.0764 20.2762 12.6287 20.2762 12.0764Z"
          fill="#C42222"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.2762 17.0764C20.2762 16.5241 20.724 16.0764 21.2762 16.0764H28.3877C28.94 16.0764 29.3877 16.5241 29.3877 17.0764C29.3877 17.6287 28.94 18.0764 28.3877 18.0764H21.2762C20.724 18.0764 20.2762 17.6287 20.2762 17.0764Z"
          fill="#C42222"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.612 22.0753C10.6124 21.523 11.0604 21.0758 11.6127 21.0764L28.3885 21.0953C28.9407 21.0959 29.3882 21.5441 29.3878 22.0964C29.3875 22.6487 28.9395 23.0959 28.3872 23.0953L11.6114 23.0764C11.0591 23.0758 10.6117 22.6276 10.612 22.0753Z"
          fill="#C42222"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.612 27.0942C10.6124 26.542 11.0604 26.0947 11.6127 26.0954L28.3885 26.1142C28.9407 26.1148 29.3882 26.5631 29.3878 27.1153C29.3875 27.6676 28.9395 28.1148 28.3872 28.1142L11.6114 28.0954C11.0591 28.0947 10.6117 27.6465 10.612 27.0942Z"
          fill="#C42222"
        />
        <path
          d="M12.6121 10.6357C11.5075 10.6357 10.6121 11.5312 10.6121 12.6357V16.4758C10.6121 17.5803 11.5075 18.4758 12.6121 18.4758H16.5121C17.6166 18.4758 18.5121 17.5803 18.5121 16.4758V12.6357C18.5121 11.5312 17.6166 10.6357 16.5121 10.6357H12.6121Z"
          fill="#C42222"
        />
      </g>
      {shouldBlend && (
        <defs>
          <filter
            id="filter0_d_1903_27115"
            x="0"
            y="0.375"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.87959 0 0 0 0 0.87959 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1903_27115"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1903_27115"
              result="shape"
            />
          </filter>
        </defs>
      )}
    </svg>
  );
};

export default Paragraph;
