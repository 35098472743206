import { FC, HTMLAttributes } from 'react';

type LongTextProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const LongText: FC<LongTextProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_549_2132)">
          <rect x="5" y="4.75" width="30" height="30" rx="6" fill="#E3F7CE" />
          <rect
            x="5.5"
            y="5.25"
            width="29"
            height="29"
            rx="5.5"
            stroke="#73C51D"
            stroke-opacity="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0002 20.0321C10.0002 19.3417 10.5588 18.7821 11.2477 18.7821H14.5867C15.2756 18.7821 15.8341 19.3417 15.8341 20.0321C15.8341 20.7225 15.2756 21.2821 14.5867 21.2821H11.2477C10.5588 21.2821 10.0002 20.7225 10.0002 20.0321Z"
            fill="#73C51D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.4519 20.0321C17.4519 19.3417 18.0104 18.7821 18.6994 18.7821L28.7527 18.7821C29.4417 18.7821 30.0002 19.3417 30.0002 20.0321C30.0002 20.7225 29.4417 21.2821 28.7527 21.2821L18.6994 21.2821C18.0104 21.2821 17.4519 20.7225 17.4519 20.0321Z"
            fill="#73C51D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0002 25.8114C10.0002 25.121 10.5588 24.5614 11.2477 24.5614L28.7528 24.5614C29.4417 24.5614 30.0002 25.121 30.0002 25.8114C30.0002 26.5018 29.4417 27.0614 28.7528 27.0614L11.2477 27.0614C10.5588 27.0614 10.0002 26.5018 10.0002 25.8114Z"
            fill="#73C51D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0002 13.6884C10.0002 12.998 10.5588 12.4384 11.2477 12.4384H28.7528C29.4417 12.4384 30.0002 12.998 30.0002 13.6884C30.0002 14.3787 29.4417 14.9384 28.7528 14.9384H11.2477C10.5588 14.9384 10.0002 14.3787 10.0002 13.6884Z"
            fill="#73C51D"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_549_2132"
            x="0"
            y="0.75"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.890196 0 0 0 0 0.968627 0 0 0 0 0.807843 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_549_2132"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_549_2132"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#E3F7CE"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#73C51D"
        stroke-opacity="0.5"
      />
      <g clip-path="url(#clip0_6187_26711)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.05078 12.6836C4.05078 12.1313 4.49759 11.6836 5.04876 11.6836H7.71991C8.27108 11.6836 8.71789 12.1313 8.71789 12.6836C8.71789 13.2359 8.27108 13.6836 7.71991 13.6836H5.04876C4.49759 13.6836 4.05078 13.2359 4.05078 12.6836Z"
          fill="#73C51D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0117 12.6836C10.0117 12.1313 10.4585 11.6836 11.0097 11.6836L19.0524 11.6836C19.6036 11.6836 20.0504 12.1313 20.0504 12.6836C20.0504 13.2359 19.6036 13.6836 19.0524 13.6836L11.0097 13.6836C10.4585 13.6836 10.0117 13.2359 10.0117 12.6836Z"
          fill="#73C51D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.05078 17.3071C4.05078 16.7548 4.49759 16.3071 5.04876 16.3071L19.0528 16.3071C19.604 16.3071 20.0508 16.7548 20.0508 17.3071C20.0508 17.8594 19.604 18.3071 19.0528 18.3071L5.04876 18.3071C4.49759 18.3071 4.05078 17.8594 4.05078 17.3071Z"
          fill="#73C51D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.05078 7.60864C4.05078 7.05636 4.49759 6.60864 5.04876 6.60864H19.0528C19.604 6.60864 20.0508 7.05636 20.0508 7.60864C20.0508 8.16093 19.604 8.60864 19.0528 8.60864H5.04876C4.49759 8.60864 4.05078 8.16093 4.05078 7.60864Z"
          fill="#73C51D"
        />
      </g>
      <defs>
        <clipPath id="clip0_6187_26711">
          <rect
            width="17.6"
            height="17.6"
            fill="white"
            transform="translate(3.25 3.65784)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LongText;
