import { ObjectSnakeCaseToCamelCase, Template } from '@formo/types';
import { z } from 'zod';

export type GetTemplatesResponse = Pick<
  Template,
  'id' | 'name' | 'description' | 'thumbnail'
>[];

export const getTemplateByIdRequest = z.object({
  params: z.object({
    templateId: z.string().min(1, 'Must provide a template ID'),
  }),
});
export type GetTemplateByIdRequest = z.infer<typeof getTemplateByIdRequest>;
export type GetTemplateByIdResponse = ObjectSnakeCaseToCamelCase<Template>;
