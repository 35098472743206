import { FC, HTMLAttributes } from 'react';

type SingleChoiceProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const SingleChoice: FC<SingleChoiceProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_549_2135)">
          <rect
            x="5.36133"
            y="4.74976"
            width="30"
            height="30"
            rx="6"
            fill="#FAD3FB"
          />
          <rect
            x="5.86133"
            y="5.24976"
            width="29"
            height="29"
            rx="5.5"
            stroke="#E451DF"
            stroke-opacity="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.3613 24.7498H13.3613V26.7498H27.3613V24.7498ZM13.3613 23.7498C12.809 23.7498 12.3613 24.1975 12.3613 24.7498V26.7498C12.3613 27.302 12.809 27.7498 13.3613 27.7498H27.3613C27.9136 27.7498 28.3613 27.302 28.3613 26.7498V24.7498C28.3613 24.1975 27.9136 23.7498 27.3613 23.7498H13.3613Z"
            fill="#C831C0"
          />
          <rect
            x="12.3613"
            y="17.7498"
            width="16"
            height="4"
            rx="1"
            fill="#C831C0"
          />
          <rect
            x="12.3613"
            y="11.7498"
            width="16"
            height="4"
            rx="1"
            fill="#C831C0"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_549_2135"
            x="0.361328"
            y="0.749756"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.980392 0 0 0 0 0.827451 0 0 0 0 0.984314 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_549_2135"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_549_2135"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#FAD3FB"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#E451DF"
        stroke-opacity="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6484 16.458H6.44844V18.058H17.6484V16.458ZM6.44844 15.658C6.00661 15.658 5.64844 16.0161 5.64844 16.458V18.058C5.64844 18.4998 6.00661 18.858 6.44844 18.858H17.6484C18.0903 18.858 18.4484 18.4998 18.4484 18.058V16.458C18.4484 16.0161 18.0903 15.658 17.6484 15.658H6.44844Z"
        fill="#C831C0"
      />
      <rect
        x="5.64844"
        y="10.8579"
        width="12.8"
        height="3.2"
        rx="1"
        fill="#C831C0"
      />
      <rect
        x="5.64844"
        y="6.05786"
        width="12.8"
        height="3.2"
        rx="1"
        fill="#C831C0"
      />
    </svg>
  );
};

export default SingleChoice;
