'use client';

import { useContext } from 'react';
import { FormBuilderContext } from '~/app/context/BuildFormContext';

function useFormBuilder() {
  const context = useContext(FormBuilderContext);

  if (!context) {
    throw new Error('useFormBuilder must be used within a FormBuilderContext');
  }

  return context;
}

export default useFormBuilder;
