import { FC, HTMLAttributes } from 'react';

type CheckboxProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Checkbox: FC<CheckboxProps> = ({
  size = 41,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_7435_3661)">
          <rect
            x="5.70801"
            y="4.49414"
            width="28"
            height="28"
            rx="6"
            fill="#FAD3FB"
          />
          <rect
            x="6.20801"
            y="4.99414"
            width="27"
            height="27"
            rx="5.5"
            stroke="#E451DF"
            strokeOpacity="0.5"
          />
          <rect
            x="10.8413"
            y="10.0938"
            width="17.7333"
            height="16.8"
            rx="2.92745"
            fill="#C831C0"
          />
          <path
            d="M23.6999 15.7637L18.211 21.2237L15.7161 18.7419"
            stroke="#FAD3FB"
            strokeWidth="1.95164"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_7435_3661"
            x="0.708008"
            y="0.494141"
            width="38"
            height="38"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.980392 0 0 0 0 0.827451 0 0 0 0 0.984314 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7435_3661"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7435_3661"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#FAD3FB"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#E451DF"
        strokeOpacity="0.5"
      />
      <rect
        x="4.44922"
        y="5.25806"
        width="15.2"
        height="14.4"
        rx="2.92745"
        fill="#C831C0"
      />
      <path
        d="M15.4722 10.118L10.7674 14.798L8.62891 12.6708"
        stroke="#FAD3FB"
        strokeWidth="1.95164"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Checkbox;
