import { FormSchema } from '@formo/types';
import { z } from 'zod';

import { validSlugRegex } from '../constants';
import { filterRequest } from './common';

export const getFormsOfTeamRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
  query: z.object({
    isActive: z.boolean().optional(),
    ...filterRequest.shape,
  }),
});
export type GetFormsOfTeamRequest = z.infer<typeof getFormsOfTeamRequest>;
export type GetFormsOfTeamResponse = (Pick<
  FormSchema,
  'id' | 'title' | 'description' | 'teamId' | 'status' | 'creatorId'
> & {
  team: {
    teamName: string;
  };
  _count: {
    responses: number;
  };
  creator: {
    name: string;
  };
  createdAt: Date;
  updatedAt: Date;
  slug?: string;
})[];

export const getFormByIdRequest = z.object({
  params: z.object({
    formId: z.string().min(1, 'Form ID is required'),
  }),
});
export type GetFormByIdRequest = z.infer<typeof getFormByIdRequest>;
export type GetFormByIdResponse = FormSchema & {
  sameAsPublished: boolean;
  teamSlug?: string;
};

export const createFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
  body: z.object({
    templateId: z.string().optional(),
    version: z.number().int().positive().min(1).optional().default(1),
  }),
});
export type CreateFormRequest = z.infer<typeof createFormRequest>;
export type CreateFormResponse = FormSchema;

export const updateFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
  body: z
    .object({
      title: z.string().min(1, 'Title is required').optional(),
      description: z.string().optional(),
      theme: z.record(z.any()).optional(),
      steps: z.array(z.any()).optional(),
      settings: z.record(z.any()).optional(),
    })
    .strip(),
});
export type UpdateFormRequest = z.infer<typeof updateFormRequest>;
export type UpdateFormResponse = FormSchema;

export const archiveFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
});
export type ArchiveFormRequest = z.infer<typeof archiveFormRequest>;
export type ArchiveFormResponse = null;

export const unarchiveFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
});
export type UnarchiveFormRequest = z.infer<typeof unarchiveFormRequest>;
export type UnarchiveFormResponse = null;

export const closeFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
});
export type CloseFormRequest = z.infer<typeof closeFormRequest>;
export type CloseFormResponse = null;

export const openFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
});
export type OpenFormRequest = z.infer<typeof openFormRequest>;
export type OpenFormResponse = null;

export const deleteFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
});
export type DeleteFormRequest = z.infer<typeof deleteFormRequest>;
export type DeleteFormResponse = null;

export const duplicateFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
});
export type DuplicateFormRequest = z.infer<typeof duplicateFormRequest>;
export type DuplicateFormResponse = FormSchema;

export const publishFormRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
  body: z
    .object({
      title: z.string().min(1, 'Title is required').optional(),
      description: z.string().optional(),
      theme: z.record(z.any()).optional(),
      steps: z.array(z.any()).optional(),
      settings: z.record(z.any()).optional(),
    })
    .strip(),
});
export type PublishFormRequest = z.infer<typeof publishFormRequest>;
export type PublishFormResponse = FormSchema;

export const getPublishedFormByIdRequest = z.object({
  params: z.object({
    formId: z.string().min(1, 'Form ID is required'),
  }),
  query: z.object({
    teamSlug: z.string().optional(),
  }),
});
export type GetPublishedFormByIdRequest = z.infer<
  typeof getPublishedFormByIdRequest
>;
export type GetPublishedFormByIdResponse = Pick<
  FormSchema,
  | 'id'
  | 'description'
  | 'status'
  | 'settings'
  | 'steps'
  | 'title'
  | 'version'
  | 'slug'
> | null;

export const updateFormSlugRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    formId: z.string().min(1, 'Form ID is required'),
  }),
  body: z.object({
    linkOption: z.enum(['default', 'subdomain']),
    formSlug: z
      .string()
      .min(1, 'Form slug is required')
      .regex(
        validSlugRegex,
        'Form slug can only contain lowercase letters, numbers, and hyphens',
      )
      .optional(),
    teamSlug: z
      .string()
      .min(1, 'Team slug is required')
      .regex(
        validSlugRegex,
        'Team slug can only contain lowercase letters, numbers, and hyphens',
      )
      .optional(),
  }),
});
export type UpdateFormSlugRequest = z.infer<typeof updateFormSlugRequest>;
export type UpdateFormSlugResponse = null;
