import { FC, HTMLAttributes } from 'react';

type ConnectTelegramProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const ConnectTelegram: FC<ConnectTelegramProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={(41 * size) / 40}
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_7660_19894)">
          <rect
            x="5"
            y="4.23633"
            width="30"
            height="30"
            rx="6"
            fill="#E9F8FF"
          />
          <rect
            x="5.5"
            y="4.73633"
            width="29"
            height="29"
            rx="5.5"
            stroke="#4CA7CE"
            stroke-opacity="0.5"
          />
          <path
            d="M10.2034 19.1515C12.1437 18.0912 14.3095 17.2062 16.3331 16.3168C19.8145 14.8599 23.3098 13.4283 26.8403 12.0955C27.5272 11.8684 28.7615 11.6463 28.8825 12.6562C28.8162 14.0858 28.5436 15.507 28.3566 16.9282C27.882 20.0535 27.3335 23.1681 26.7985 26.2832C26.6142 27.3208 25.3041 27.8579 24.4658 27.1939C22.4511 25.8438 20.421 24.5068 18.4321 23.1254C17.7806 22.4687 18.3847 21.5255 18.9666 21.0564C20.6259 19.4341 22.3857 18.0557 23.9583 16.3495C24.3825 15.3332 23.1291 16.1897 22.7156 16.4522C20.444 18.0052 18.228 19.6531 15.833 21.018C14.6096 21.6861 13.1838 21.1152 11.961 20.7424C10.8646 20.292 9.25797 19.8383 10.2033 19.1516L10.2034 19.1515Z"
            fill="#33B0E6"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_7660_19894"
            x="0"
            y="0.236328"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.862745 0 0 0 0 0.87451 0 0 0 0 0.937255 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7660_19894"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7660_19894"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.236328" width="24" height="24" rx="6" fill="#E9F8FF" />
      <rect
        x="0.5"
        y="0.736328"
        width="23"
        height="23"
        rx="5.5"
        stroke="#4CA7CE"
        stroke-opacity="0.5"
      />
      <g clip-path="url(#clip0_6423_70424)">
        <path
          d="M4.16275 12.1685C5.71493 11.3203 7.44758 10.6124 9.06649 9.90078C11.8516 8.73528 14.6478 7.58999 17.4723 6.52373C18.0218 6.34206 19.0092 6.16442 19.106 6.97234C19.0529 8.11599 18.8349 9.25294 18.6853 10.3899C18.3056 12.8902 17.8668 15.3819 17.4388 17.8739C17.2914 18.704 16.2433 19.1337 15.5726 18.6025C13.9609 17.5224 12.3368 16.4528 10.7457 15.3477C10.2245 14.8223 10.7078 14.0677 11.1733 13.6925C12.5007 12.3946 13.9085 11.2919 15.1666 9.92696C15.506 9.11395 14.5033 9.79913 14.1725 10.0091C12.3552 11.2515 10.5824 12.5698 8.6664 13.6618C7.68771 14.1963 6.54703 13.7395 5.56879 13.4413C4.69168 13.081 3.40638 12.718 4.16266 12.1686L4.16275 12.1685Z"
          fill="#33B0E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_6423_70424">
          <rect
            width="17.6"
            height="17.6"
            fill="white"
            transform="translate(3.2002 3.43652)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConnectTelegram;
