export enum TEAM_ACTIONS {
  // Members
  /**
   * Add, remove, and manage members
   */
  MEMBERS_MANAGEMENT = 'MEMBERS_MANAGEMENT',
  /**
   * Transfer ownership to another member
   */
  TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
  /**
   * Manage roles of members, cannot change to owners
   */
  ROLES_MANAGEMENT = 'ROLES_MANAGEMENT',
  // Settings
  /**
   * Manage team settings (names, tiers, etc.)
   */
  SETTINGS_MANAGEMENT = 'SETTINGS_MANAGEMENT',
  /**
   * Delete the team permanently
   */
  DELETE_TEAM = 'DELETE_TEAM',
  // Forms
  /**
   * View forms and responses
   */
  VIEW_FORMS = 'VIEW_FORMS',
  /**
   * Create and edit forms
   */
  CREATE_UPDATE_FORM = 'CREATE_UPDATE_FORM',
  /**
   * Archive forms, removing them from the active list
   */
  ARCHIVE_FORMS = 'ARCHIVE_FORMS',
  /**
   * Close forms, removing them from the active list
   */
  CLOSE_FORMS = 'CLOSE_FORMS',
  /**
   * Delete forms permanently
   */
  DELETE_FORMS = 'DELETE_FORMS',
  /**
   * View responses of forms
   */
  VIEW_RESPONSES = 'VIEW_RESPONSES',
  /**
   * Manage responses of forms
   */
  RESPONSES_MANAGEMENT = 'RESPONSES_MANAGEMENT',
  /**
   * View projects
   */
  VIEW_PROJECTS = 'VIEW_PROJECTS',
  /**
   * Create and edit projects
   */
  CREATE_UPDATE_PROJECTS = 'CREATE_UPDATE_PROJECTS',
  /**
   * Delete projects permanently
   */
  DELETE_PROJECTS = 'DELETE_PROJECTS',
}

export enum TEAM_ROLES {
  /**
   * The creator of the team, can only have one
   */
  OWNER = 'OWNER',
  /**
   * Help with managing the team
   */
  ADMIN = 'ADMIN',
  /**
   * Manage forms and submissions
   */
  EDITOR = 'EDITOR',
  /**
   * Can view forms and submissions
   */
  MEMBER = 'MEMBER',
}

export const ROLE_ORDER = {
  [TEAM_ROLES.OWNER]: 0,
  [TEAM_ROLES.ADMIN]: 1,
  [TEAM_ROLES.EDITOR]: 2,
  [TEAM_ROLES.MEMBER]: 3,
};

export const TEAM_PERMISSIONS = {
  // Owners can do everything
  [TEAM_ROLES.OWNER]: Object.values(TEAM_ACTIONS),
  [TEAM_ROLES.ADMIN]: [
    TEAM_ACTIONS.MEMBERS_MANAGEMENT,
    TEAM_ACTIONS.ROLES_MANAGEMENT,
    TEAM_ACTIONS.SETTINGS_MANAGEMENT,
    TEAM_ACTIONS.VIEW_FORMS,
    TEAM_ACTIONS.CREATE_UPDATE_FORM,
    TEAM_ACTIONS.VIEW_PROJECTS,
    TEAM_ACTIONS.CREATE_UPDATE_PROJECTS,
    TEAM_ACTIONS.ARCHIVE_FORMS,
    TEAM_ACTIONS.VIEW_RESPONSES,
    TEAM_ACTIONS.RESPONSES_MANAGEMENT,
  ],
  [TEAM_ROLES.EDITOR]: [
    TEAM_ACTIONS.VIEW_FORMS,
    TEAM_ACTIONS.VIEW_PROJECTS,
    TEAM_ACTIONS.CREATE_UPDATE_FORM,
    TEAM_ACTIONS.ARCHIVE_FORMS,
    TEAM_ACTIONS.VIEW_RESPONSES,
  ],
  [TEAM_ROLES.MEMBER]: [
    TEAM_ACTIONS.VIEW_FORMS,
    TEAM_ACTIONS.VIEW_PROJECTS,
    TEAM_ACTIONS.VIEW_RESPONSES,
  ],
};

export enum TEAM_INVITE_STATUS {
  PENDING = 0,
  ACCEPTED = 1,
  DECLINED = 2,
  EXPIRED = 3,
}
