import { FC, HTMLAttributes } from 'react';

type RatingProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Rating: FC<RatingProps> = ({
  size = 38,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_549_2133)">
          <rect
            x="5.36133"
            y="4.375"
            width="30"
            height="30"
            rx="6"
            fill="#FFF1D2"
          />
          <rect
            x="5.86133"
            y="4.875"
            width="29"
            height="29"
            rx="5.5"
            stroke="#F1A500"
            stroke-opacity="0.5"
          />
          <path
            d="M21.099 10.7234L23.4283 15.426C23.4813 15.5454 23.5646 15.6488 23.67 15.7259C23.7754 15.803 23.8992 15.8512 24.029 15.8655L29.1711 16.6273C29.3199 16.6465 29.4603 16.7076 29.5759 16.8035C29.6913 16.8994 29.777 17.0262 29.8232 17.1691C29.8692 17.312 29.8737 17.465 29.8361 17.6103C29.7984 17.7556 29.7202 17.8872 29.6106 17.9898L25.9042 21.6669C25.8095 21.7553 25.7386 21.8659 25.6976 21.9887C25.6567 22.1115 25.6471 22.2426 25.6698 22.3701L26.5634 27.5415C26.5893 27.6902 26.5729 27.843 26.5162 27.9828C26.4595 28.1225 26.3646 28.2435 26.2426 28.3322C26.1204 28.4206 25.976 28.4731 25.8255 28.4835C25.675 28.494 25.5246 28.462 25.3914 28.3912L20.762 25.9447C20.6435 25.8865 20.5132 25.8562 20.3811 25.8562C20.2491 25.8562 20.1188 25.8865 20.0002 25.9447L15.3709 28.3912C15.2377 28.462 15.0873 28.494 14.9368 28.4835C14.7864 28.4731 14.6418 28.4206 14.5197 28.3322C14.3976 28.2435 14.3028 28.1225 14.2461 27.9828C14.1894 27.843 14.173 27.6902 14.1989 27.5415L15.0925 22.3115C15.1152 22.184 15.1056 22.0529 15.0647 21.9301C15.0237 21.8073 14.9527 21.6967 14.8581 21.6083L11.1077 17.9898C10.9968 17.8844 10.9188 17.7492 10.8831 17.6004C10.8475 17.4516 10.8557 17.2957 10.9069 17.1515C10.958 17.0073 11.0499 16.8811 11.1714 16.788C11.2928 16.695 11.4387 16.6392 11.5912 16.6273L16.7333 15.8655C16.8631 15.8512 16.9869 15.803 17.0923 15.7259C17.1976 15.6488 17.281 15.5454 17.334 15.426L19.6633 10.7234C19.7267 10.5864 19.828 10.4705 19.9552 10.3892C20.0824 10.308 20.2302 10.2648 20.3811 10.2648C20.5321 10.2648 20.6799 10.308 20.8071 10.3892C20.9343 10.4705 21.0356 10.5864 21.099 10.7234Z"
            fill="#F1A500"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_549_2133"
            x="0.361328"
            y="0.375"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.964706 0 0 0 0 0.839216 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_549_2133"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_549_2133"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#FFF1D2"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#F1A500"
        stroke-opacity="0.5"
      />
      <path
        d="M12.6393 5.53671L14.5028 9.29881C14.5452 9.39429 14.6119 9.47701 14.6962 9.5387C14.7805 9.60039 14.8795 9.63891 14.9833 9.65041L19.097 10.2598C19.2161 10.2752 19.3284 10.324 19.4209 10.4008C19.5132 10.4775 19.5818 10.579 19.6187 10.6933C19.6555 10.8076 19.6591 10.93 19.629 11.0462C19.5989 11.1625 19.5363 11.2678 19.4486 11.3498L16.4835 14.2915C16.4078 14.3622 16.3511 14.4507 16.3182 14.549C16.2855 14.6472 16.2778 14.7521 16.296 14.8541L17.0109 18.9912C17.0316 19.1101 17.0185 19.2324 16.9732 19.3442C16.9278 19.456 16.8518 19.5528 16.7542 19.6237C16.6565 19.6945 16.5409 19.7365 16.4206 19.7448C16.3002 19.7532 16.1798 19.7275 16.0733 19.6709L12.3698 17.7137C12.275 17.6672 12.1707 17.6429 12.0651 17.6429C11.9594 17.6429 11.8552 17.6672 11.7604 17.7137L8.05685 19.6709C7.9503 19.7275 7.83 19.7532 7.70962 19.7448C7.58925 19.7365 7.47363 19.6945 7.37595 19.6237C7.27825 19.5528 7.2024 19.456 7.15702 19.3442C7.11164 19.2324 7.09856 19.1101 7.11926 18.9912L7.83417 14.8072C7.85229 14.7052 7.84463 14.6003 7.81188 14.5021C7.77912 14.4038 7.72233 14.3153 7.64665 14.2446L4.64635 11.3498C4.55759 11.2655 4.49516 11.1573 4.46664 11.0383C4.43812 10.9193 4.44472 10.7945 4.48566 10.6792C4.52658 10.5638 4.60009 10.4628 4.69725 10.3884C4.79442 10.314 4.91108 10.2693 5.03311 10.2598L9.14681 9.65041C9.25065 9.63891 9.34966 9.60039 9.43397 9.5387C9.51827 9.47701 9.58494 9.39429 9.62732 9.29881L11.4908 5.53671C11.5415 5.42714 11.6226 5.33437 11.7243 5.26936C11.8261 5.20435 11.9443 5.1698 12.0651 5.1698C12.1858 5.1698 12.3041 5.20435 12.4058 5.26936C12.5076 5.33437 12.5886 5.42714 12.6393 5.53671Z"
        fill="#F1A500"
      />
    </svg>
  );
};

export default Rating;
