import { ObjectSnakeCaseToCamelCase, Token } from '@formo/types';
import { z } from 'zod';

import { filterRequest, paginationRequest } from './common';

export const getTokensRequest = z.object({
  query: z.object({
    chainId: z.coerce.number().optional().default(1),
    ...paginationRequest.shape,
    ...filterRequest.shape,
  }),
});
export type GetTokensRequest = z.infer<typeof getTokensRequest>;
export type GetTokensResponse = ObjectSnakeCaseToCamelCase<
  Pick<
    Token,
    'address' | 'chain_id' | 'logo_uri' | 'name' | 'symbol' | 'decimals'
  >
>[];

export const getTokenByAddressRequest = z.object({
  query: z.object({
    chainId: z.coerce.number().optional().default(1),
  }),
  params: z.object({
    address: z.string().min(1, 'Must provide a contract address'),
  }),
});
export type GetTokenByAddressRequest = z.infer<typeof getTokenByAddressRequest>;
export type GetTokenByAddressResponse = ObjectSnakeCaseToCamelCase<
  Pick<
    Token,
    'address' | 'chain_id' | 'logo_uri' | 'name' | 'symbol' | 'decimals'
  >
>;

export const getNftByAddressRequest = z.object({
  query: z.object({
    chainId: z.coerce.number().optional().default(1),
  }),
  params: z.object({
    address: z.string().min(1, 'Must provide a contract address'),
  }),
});
export type GetNftByAddressRequest = z.infer<typeof getNftByAddressRequest>;
export type GetNftByAddressResponse = ObjectSnakeCaseToCamelCase<
  Pick<
    Token,
    'address' | 'chain_id' | 'logo_uri' | 'name' | 'symbol' | 'decimals'
  >
>;

export const getNftBalanceRequest = z.object({
  query: z.object({
    address: z.string().min(1, 'Must provide a wallet address'),
    chainId: z.coerce.number().optional().default(1),
    contractAddress: z.string().min(1, 'Must provide a contract address'),
  }),
});
export type GetNftBalanceRequest = z.infer<typeof getNftBalanceRequest>;
export type GetNftBalanceResponse = {
  /** Return in HEX string */
  balance: string;
} & Record<string, any>;

export const getTokenBalanceRequest = z.object({
  query: z.object({
    address: z.string().min(1, 'Must provide a wallet address'),
    chainId: z.coerce.number().optional().default(1),
    tokenAddress: z.string().min(1, 'Token address can not be empty'),
  }),
});
export type GetTokenBalanceRequest = z.infer<typeof getTokenBalanceRequest>;
export type GetTokenBalanceResponse = {
  /** Return in HEX string */
  tokenBalance: string;
} & Record<string, any>;

export const getNativeTokenBalanceRequest = z.object({
  query: z.object({
    address: z.string().min(1, 'Must provide a wallet address'),
    chainId: z.coerce.number().optional().default(1),
  }),
});
export type GetNativeTokenBalanceRequest = z.infer<
  typeof getNativeTokenBalanceRequest
>;
export type GetNativeTokenBalanceResponse = {
  /** Return in HEX string */
  tokenBalance: string;
} & Record<string, any>;
