export enum ERROR_CODES {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVALID_VALIDATION_REQUEST = 'INVALID_VALIDATION_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  BAD_REQUEST = 'BAD_REQUEST',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  INVALID_CHAIN_ID = 'INVALID_CHAIN_ID',
  // Add more error codes here
}
