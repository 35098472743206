import { z } from 'zod';

export const isEmailExistWithProviderRequest = z.object({
  body: z.object({
    email: z.string().email(),
    provider: z.string().min(1, 'Provider is required'),
  }),
});
export type IsEmailExistWithProviderRequest = z.infer<
  typeof isEmailExistWithProviderRequest
>;
export type IsEmailExistWithProviderResponse = {
  exists: boolean;
};
