import { FC, HTMLAttributes } from 'react';

type ShortTextProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const ShortText: FC<ShortTextProps> = ({
  size = 40,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_549_2140)">
          <rect x="5" y="4.75" width="30" height="30" rx="6" fill="#E3F7CE" />
          <rect
            x="5.5"
            y="5.25"
            width="29"
            height="29"
            rx="5.5"
            stroke="#73C51D"
            stroke-opacity="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 22.9219C10 22.2315 10.5585 21.6719 11.2475 21.6719H21.2158C21.9048 21.6719 22.4633 22.2315 22.4633 22.9219C22.4633 23.6122 21.9048 24.1719 21.2158 24.1719H11.2475C10.5585 24.1719 10 23.6122 10 22.9219Z"
            fill="#73C51D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 16.5781C10 15.8878 10.5585 15.3281 11.2475 15.3281H28.7525C29.4415 15.3281 30 15.8878 30 16.5781C30 17.2685 29.4415 17.8281 28.7525 17.8281H11.2475C10.5585 17.8281 10 17.2685 10 16.5781Z"
            fill="#73C51D"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_549_2140"
            x="0"
            y="0.75"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.890196 0 0 0 0 0.968627 0 0 0 0 0.807843 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_549_2140"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_549_2140"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#E3F7CE"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#73C51D"
        stroke-opacity="0.5"
      />
      <g clip-path="url(#clip0_6167_22657)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.05078 14.9954C4.05078 14.4431 4.49759 13.9954 5.04876 13.9954H13.0234C13.5746 13.9954 14.0214 14.4431 14.0214 14.9954C14.0214 15.5476 13.5746 15.9954 13.0234 15.9954H5.04876C4.49759 15.9954 4.05078 15.5476 4.05078 14.9954Z"
          fill="#73C51D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.05078 9.92041C4.05078 9.36813 4.49759 8.92041 5.04876 8.92041H19.0528C19.604 8.92041 20.0508 9.36813 20.0508 9.92041C20.0508 10.4727 19.604 10.9204 19.0528 10.9204H5.04876C4.49759 10.9204 4.05078 10.4727 4.05078 9.92041Z"
          fill="#73C51D"
        />
      </g>
      <defs>
        <clipPath id="clip0_6167_22657">
          <rect
            width="17.6"
            height="17.6"
            fill="white"
            transform="translate(3.25 3.65796)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShortText;
