export const chainIdToNameMapper: Record<number, string> = {
  1: 'Ethereum Mainnet',
  11155111: 'Ethereum Sepolia',

  42161: 'Arbitrum Mainnet',
  42170: 'Arbitrum Nova',
  421613: 'Arbitrum Sepolia',

  10: 'Optimism Mainnet',
  11155420: 'Optimism Sepolia',

  137: 'Polygon Mainnet',
  80002: 'Polygon Amoy',

  1101: 'Polygon ZkEVM',
  2442: 'Polygon ZkEVM Cardona',

  8453: 'Base Mainnet',
  84532: 'Base Sepolia',

  43114: 'Avalanche Mainnet',
  43113: 'Avalanche Fuji',

  5000: 'Mantle Mainnet',
  5003: 'Mantle Sepolia',

  59144: 'Linea Mainnet',
  59141: 'Linea Sepolia',

  534352: 'Scroll Mainnet',
  534351: 'Scroll Sepolia',

  324: 'zkSync Era Mainnet',
  300: 'zkSync Era Sepolia',

  81457: 'Blast Mainnet',
  168587773: 'Blast Sepolia',

  56: 'BNB Smart Chain Mainnet',
  97: 'BNB Smart Chain Testnet',

  1513: 'Story Testnet',

  480: 'World Chain',
  4801: 'World Chain Sepolia',

  80084: 'Berachain bArtio',

  7777777: 'Zora',
  999999999: 'Zora Sepolia',

  252: 'Fraxtal',
  2522: 'Fraxtal Testnet',

  1946: 'Soneium Minato',

  11124: 'Abstract Testnet',

  13371: 'ImmutableX',
  13473: 'ImmutableX Testnet',

  34443: 'Mode',
  9191: 'Mode Testnet',

  100: 'Gnosis',
  10200: 'Gnosis Chiado',

  42220: 'Celo',
  44787: 'Celo Alfajores',

  1301: 'Unichain Sepolia',

  8217: 'Kaia',
  1001: 'Kaia Kairos',

  911867: 'Odyssey',

  690: 'Redstone',
  17069: 'Redstone Garnet',

  2818: 'Morph',
  2810: 'Morph Testnet',

  763373: 'Ink Sepolia',
};

export const chainIdToNativeTokenMapper: Record<number, string> = {
  1: 'ETH',
  11155111: 'Sepolia Testnet ETH',

  42161: 'Arbitrum ETH',
  42170: 'Arbitrum Nova ETH',
  421613: 'Arbitrum Sepolia Testnet ETH',

  10: 'OP ETH',
  11155420: 'OP Sepolia ETH',

  137: 'POL',
  80002: 'Polygon Amoy Testnet POL',

  1101: 'Polygon ZkEVM ETH',
  2442: 'Polygon ZkEVM Cardona ETH',

  8453: 'BASE Mainnet ETH',
  84532: 'BASE Sepolia ETH',

  43114: 'AVAX',
  43113: 'Testnet AVAX',

  5000: 'MNT',
  5003: 'Testnet MNT',

  59144: 'Linea ETH',
  59141: 'Linea Sepolia ETH',

  534352: 'ETH',
  534351: 'Scroll Sepolia ETH',

  324: 'ETH',
  300: 'zkSync Era Sepolia ETH',

  81457: 'ETH',
  168587773: 'Blast Sepolia ETH',

  56: 'BNB',
  97: 'Testnet BNB',

  1513: 'Story Testnet IP',

  480: 'World Chain ETH',
  4801: 'World Chain Sepolia ETH',

  80084: 'Berachain bArtio BERA',

  7777777: 'Zora ETH',
  999999999: 'Zora Sepolia ETH',

  252: 'Fraxtal ETH',
  2522: 'Fraxtal Testnet ETH',

  1946: 'Soneium Minato ETH',

  11124: 'Abstract Testnet ETH',

  13371: 'ImmutableX IMX',
  13473: 'ImmutableX Testnet IMX',

  34443: 'Mode ETH',
  919: 'Mode Testnet ETH',

  100: 'Gnosis xDAI',
  10200: 'Gnosis Chiado xDAI',

  42220: 'Celo CELO',
  44787: 'Celo Alfajores CELO',

  1301: 'Unichain Sepolia ETH',

  8217: 'Kaia KLAY',
  1001: 'Kaia Kairos KLAY',

  911867: 'Odyssey ETH',

  690: 'Redstone ETH',
  17069: 'Redstone Garnet ETH',

  2818: 'Morph ETH',
  2810: 'Morph Testnet ETH',

  763373: 'Ink Sepolia ETH',
};

export const chainIdToNativeTokenNameMapper: Record<number, string> = {
  1: 'ETH',
  11155111: 'ETH',

  42161: 'ETH',
  42170: 'ETH',
  421613: 'ETH',

  10: 'ETH',
  11155420: 'ETH',

  137: 'POL',
  80002: 'POL',

  1101: 'ETH',
  2442: 'ETH',

  8453: 'ETH',
  84532: 'ETH',

  43114: 'AVAX',
  43113: 'AVAX',

  5000: 'MNT',
  5003: 'MNT',

  59144: 'ETH',
  59141: 'ETH',

  534352: 'ETH',
  534351: 'ETH',

  324: 'ETH',
  300: 'ETH',

  81457: 'ETH',
  168587773: 'ETH',

  56: 'BNB',
  97: 'tBNB',

  1513: 'IP',

  480: 'ETH',
  4801: 'ETH',

  80084: 'BERA',

  7777777: 'ETH',
  999999999: 'ETH',

  252: 'frxETH',
  2522: 'frxETH',

  1946: 'ETH',

  11124: 'ETH',

  13371: 'IMX',
  13473: 'IMX',

  34443: 'ETH',
  919: 'ETH',

  100: 'xDAI',
  10200: 'xDAI',

  42220: 'CELO',
  44787: 'CELO',

  1301: 'ETH',

  8217: 'KLAY',
  1001: 'KLAY',

  911867: 'ETH',

  690: 'ETH',
  17069: 'ETH',

  2818: 'ETH',
  2810: 'ETH',

  763373: 'ETH',
};
