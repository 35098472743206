import { Invite, ObjectSnakeCaseToCamelCase, UserTeam } from '@formo/types';
import { z } from 'zod';

import { TEAM_ROLES } from '../constants';

export const inviteUserRequest = z.object({
  body: z.object({
    email: z.string().email(),
    role: z.enum([TEAM_ROLES.ADMIN, TEAM_ROLES.MEMBER, TEAM_ROLES.EDITOR]),
  }),
  params: z.object({
    teamId: z.string().uuid(),
  }),
});

export type InviteUserRequest = z.infer<typeof inviteUserRequest>;

export type InviteUserResponse = ObjectSnakeCaseToCamelCase<Invite>;

export const getInviteByIdRequest = z.object({
  params: z.object({
    inviteId: z.string().uuid(),
  }),
  query: z.object({
    token: z.string().min(40, 'Invalid token'), // All tokens are 40 characters long
    email: z.string().email().optional(),
  }),
});

export type GetInviteByIdRequest = z.infer<typeof getInviteByIdRequest>;

export type GetInviteByIdResponse = ObjectSnakeCaseToCamelCase<
  Invite & {
    team: { teamName: string };
    sender: {
      name: string;
      email: string;
    };
  }
>;

export const getTeamInvitesRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
  query: z.object({
    status: z.string().optional(),
  }),
});

export type GetTeamInvitesRequest = z.infer<typeof getTeamInvitesRequest>;

export type GetTeamInvitesResponse = ObjectSnakeCaseToCamelCase<Invite>[];

export const acceptInviteRequest = z.object({
  params: z.object({
    inviteId: z.string().uuid(),
  }),
  body: z.object({
    token: z.string().min(40, 'Invalid token'), // All tokens are 40 characters long
  }),
});

export type AcceptInviteRequest = z.infer<typeof acceptInviteRequest>;

export type AcceptInviteResponse = ObjectSnakeCaseToCamelCase<UserTeam>;

export const declineInviteRequest = z.object({
  params: z.object({
    inviteId: z.string().uuid(),
  }),
  body: z.object({
    token: z.string().min(40, 'Invalid token'), // All tokens are 40 characters long
  }),
});

export type DeclineInviteRequest = z.infer<typeof declineInviteRequest>;

export type DeclineInviteResponse = null;

export const resendInviteRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    inviteId: z.string().uuid(),
  }),
});

export type ResendInviteRequest = z.infer<typeof resendInviteRequest>;

export type ResendInviteResponse = ObjectSnakeCaseToCamelCase<Invite>;

export const cancelInviteRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    inviteId: z.string().uuid(),
  }),
});

export type CancelInviteRequest = z.infer<typeof cancelInviteRequest>;

export type CancelInviteResponse = null;

export const updateInviteRoleRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    inviteId: z.string().uuid(),
  }),
  body: z.object({
    role: z.enum([TEAM_ROLES.ADMIN, TEAM_ROLES.MEMBER, TEAM_ROLES.EDITOR]),
  }),
});

export type UpdateInviteRoleRequest = z.infer<typeof updateInviteRoleRequest>;

export type UpdateInviteRoleResponse = ObjectSnakeCaseToCamelCase<Invite>;
