import { z } from 'zod';

export const paginationRequest = z.object({
  page: z.coerce.number().int().positive().optional().default(1),
  size: z.coerce.number().int().positive().optional().default(10),
});

export const filterRequest = z.object({
  searchBy: z.string().optional(),
  searchQuery: z.string().optional(),
  sortBy: z.string().optional(),
  sortOrder: z.enum(['asc', 'desc']).optional().default('desc'),
});
