'use client';

import { GetFormByIdResponse } from '@formo/shared';
import {
  BaseFieldSchema,
  FormSchema,
  StepSchema,
  TokenGateSetting,
} from '@formo/types';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'next/navigation';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ChoiceType,
  DisplayTextType,
  Field,
  FieldSpecs,
  FieldSpecsType,
  IdentityType,
  ImageAlignment,
  MediaType,
  Option,
  OthersType,
  RatingType,
  StepType,
  TextType,
} from '~/constants/fields';
import { FORM_STATUS } from '~/constants/status';
import client from '~/lib/client';
import { useSupabaseBucket } from '~/shared/utils/useSupabase';
import nanoid from '~/utils/nanoid';

import { FormElementInstance } from '../_components/Builder/components/common/FormElements';
import {
  ImageFile,
  UploadImageType,
} from '../_components/form/ImageField/shared/common';

export const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
export const storagePath = process.env.NEXT_PUBLIC_SUPABASE_STORAGE_PATH;

const DEFAULT_STORAGE_NAME = 'form-uploaded-images';
const DEFAULT_IMAGE_PATH = 'default.jpg';
const HEADING_LABEL = 'Heading text here';
const DEFAULT_LABEL = 'Type your question here';
const DEFAULT_PARAGRAPH_LABEL = 'Enter your text here';
const DEFAULT_IMAGE_HEIGHT = 200;

const supabaseStorageUrl = `${supabaseUrl}/${storagePath}/${DEFAULT_STORAGE_NAME}`;
const defaultImageUrl = `${supabaseStorageUrl}/${DEFAULT_IMAGE_PATH}`;

type SchemaConstructor = Record<
  Field,
  Partial<Record<FieldSpecsType, (id: string) => BaseFieldSchema>>
>;

type Form = FormSchema & {
  sameAsPublished?: boolean;
  teamSlug?: string;
};

export const fieldTypeToSchemaConstructor: SchemaConstructor = {
  input: {
    text: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: TextType.ShortText,
          required: false,
          minLength: 0,
          maxLength: 150,
        },
      }),
    email: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: TextType.Email,
          required: false,
        },
      }),
    textarea: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: TextType.LongText,
          required: false,
          minLength: 0,
          maxLength: 1000,
          rows: 5,
        },
      }),
    select: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: ChoiceType.Select,
          options: [
            { label: 'Option 1', value: `option_${nanoid()}` },
            { label: 'Option 2', value: `option_${nanoid()}` },
          ],
          required: false,
        },
      }),
    'radio-group': (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: ChoiceType.RadioGroup,
          options: [
            { label: 'Option 1', value: `option_${nanoid()}` },
            { label: 'Option 2', value: `option_${nanoid()}` },
          ],
          required: false,
          horizontalAlignment: false,
        },
      }),
    'checkbox-group': (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: ChoiceType.CheckboxGroup,
          options: [
            { label: 'Option 1', value: `option_${nanoid()}` },
            { label: 'Option 2', value: `option_${nanoid()}` },
          ],
          required: false,
        },
      }),
    rating: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: RatingType.Rating,
          required: false,
        },
      }),
    file: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          src: '',
          type: OthersType.FileUpload,
          required: false,
        },
      }),
    url: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'input',
        fieldSpecs: {
          type: OthersType.Url,
          required: false,
        },
      }),
  },
  text: {
    heading: (id: string) =>
      createBaseField({
        id,
        label: HEADING_LABEL,
        fieldType: 'text',
        fieldSpecs: {
          type: DisplayTextType.Heading,
        },
      }),
    paragraph: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_PARAGRAPH_LABEL,
        fieldType: 'text',
        fieldSpecs: {
          type: DisplayTextType.Paragraph,
        },
      }),
  },
  identity: {
    discord: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'identity',
        fieldSpecs: {
          type: IdentityType.Discord,
          required: false,
        },
      }),
    farcaster: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'identity',
        fieldSpecs: {
          type: IdentityType.Farcaster,
          required: false,
        },
      }),
    twitter: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'identity',
        fieldSpecs: {
          type: IdentityType.Twitter,
          required: false,
        },
      }),
    wallet: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'identity',
        fieldSpecs: {
          type: IdentityType.Wallet,
          required: false,
        },
      }),
    telegram: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'identity',
        fieldSpecs: {
          type: IdentityType.Telegram,
          required: false,
        },
      }),
    'world-id': (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'identity',
        fieldSpecs: {
          type: IdentityType.WorldId,
          required: false,
        },
      }),
    solana: (id: string) =>
      createBaseField({
        id,
        label: DEFAULT_LABEL,
        fieldType: 'identity',
        fieldSpecs: {
          type: IdentityType.Solana,
          required: false,
        },
      }),
  },
  image: {
    formImage: (id: string) =>
      createBaseField({
        id,
        label: '',
        fieldType: 'image',
        fieldSpecs: {
          file: {
            name: 'Untitled',
            preview: defaultImageUrl,
          },
          type: MediaType.Image,
          dialogTriggerText: 'Change',
          storageName: DEFAULT_STORAGE_NAME,
          height: DEFAULT_IMAGE_HEIGHT,
          imageAlignment: ImageAlignment.Center,
        },
      }),
  },
};

const createBaseField = <T extends BaseFieldSchema>(schema: T): T => ({
  ...schema,
});

const createBaseStep = <T extends StepSchema>(schema: T): T => ({
  ...schema,
});

export type Slug = {
  teamSlug?: string;
  formSlug?: string;
  linkOption: 'default' | 'subdomain';
};

type FormBuilderContextType = {
  form?: Form;

  selectedElement: FormElementInstance | null;
  setSelectedElement: Dispatch<SetStateAction<FormElementInstance | null>>;

  selectedStep: StepSchema;
  setSelectedStep: Dispatch<SetStateAction<StepSchema>>;

  steps: StepSchema[];
  setSteps: Dispatch<SetStateAction<StepSchema[]>>;

  theme: Record<string, any>;
  setTheme: Dispatch<SetStateAction<Record<string, any>>>;

  preview: Record<string, any>;
  setPreview: Dispatch<SetStateAction<Record<string, any>>>;

  renameForm: (newName: string) => void;

  addField: (fieldSpecsType: FieldSpecsType, fieldType: Field) => void;
  handleDeleteField: (fieldId: string) => void;
  addOptionToField: (
    stepId: string,
    fieldId: string,
    newOption: { label: string; value: string },
  ) => void;

  updateFieldOptionLabel: (
    stepId: string,
    fieldId: string,
    optionId: string,
    newLabel: string,
  ) => void;
  changeFieldType: (
    fieldId: string,
    fieldSpecsType: FieldSpecsType,
    fieldType: Field,
  ) => void;
  updateFieldRequiredStatus: (fieldId: string, isRequired: boolean) => void;
  changeSingleChoiceAlignment: (
    fieldId: string,
    horizontalAlignment: boolean,
  ) => void;
  changeFieldPlaceholder: (fieldId: string, newPlaceholder: string) => void;
  changeTextareaRows: (fieldId: string, rows: number) => void;
  updateFieldOptionsOrder: (
    stepId: string,
    fieldId: string,
    options: Option[],
  ) => void;
  setAllowOtherOption: (
    stepId: string,
    fieldId: string,
    allowOther: boolean,
  ) => void;
  deleteFieldOption: (
    stepId: string,
    fieldId: string,
    optionId: string,
  ) => void;
  changeQuestion: (fieldId: string, question: string) => void;
  changeSubText: (fieldId: string, question: string) => void;

  changeImage: (file: ImageFile, fieldId: string) => void;
  changeFile: (
    file: { type: string; src: string; required: boolean },
    fieldId: string,
  ) => void;
  changeImageHeight: (fieldId: string, height: number) => void;
  changeImageAlignment: (
    fieldId: string,
    imageAlignment: ImageAlignment,
  ) => void;
  handleDuplicateField: (formId: string, field: FormElementInstance) => void;
  updateFieldCharLimits: (
    fieldId: string,
    minLength: number | null,
    maxLength: number | null,
  ) => void;

  updateFieldSelectionLimits: (
    fieldId: string,
    minSelection: number | null,
    maxSelection: number | null,
  ) => void;

  addStep: (props: {
    name: string;
    type: string;
    description?: string;
    fields?: BaseFieldSchema[];
  }) => void;
  handleDuplicateStep: (step: StepSchema) => void;
  renameStep: (stepId: string, newName: string) => void;
  deleteStep: (stepId: string) => void;

  tokenGate: TokenGateSetting;
  setTokenGate: Dispatch<SetStateAction<TokenGateSetting>>;

  toggleEndingIcon: (stepId: string, v: boolean) => void;

  isResponder: boolean;

  isSameAsPublished: boolean;
  setIsSameAsPublished: Dispatch<SetStateAction<boolean>>;

  formStatus: FORM_STATUS;
  setFormStatus: Dispatch<SetStateAction<FORM_STATUS>>;

  isLoadingForm: boolean;

  slug: Slug;
  setSlug: Dispatch<SetStateAction<Slug>>;
};

export const FormBuilderContext = createContext<FormBuilderContextType>({
  form: undefined,

  selectedElement: null,
  setSelectedElement: () => {},

  selectedStep: {} as StepSchema,
  setSelectedStep: () => {},

  steps: [],
  setSteps: () => {},

  theme: {},
  setTheme: () => {},

  preview: {},
  setPreview: () => {},

  renameForm: () => {},

  addField: () => {},
  handleDeleteField: () => {},
  addOptionToField: () => {},

  updateFieldOptionLabel: () => {},
  changeFieldType: () => {},
  updateFieldRequiredStatus: () => {},
  changeSingleChoiceAlignment: () => {},
  changeFieldPlaceholder: () => {},
  changeTextareaRows: () => {},
  updateFieldOptionsOrder: () => {},
  setAllowOtherOption: () => {},
  deleteFieldOption: () => {},
  changeQuestion: () => {},
  changeSubText: () => {},

  changeImage: () => {},
  changeFile: () => {},
  changeImageHeight: () => {},
  changeImageAlignment: () => {},
  handleDuplicateField: () => {},
  updateFieldCharLimits: () => {},
  updateFieldSelectionLimits: () => {},

  addStep: () => {},
  handleDuplicateStep: () => {},
  renameStep: () => {},
  deleteStep: () => {},

  tokenGate: {} as TokenGateSetting,
  setTokenGate: () => {},

  toggleEndingIcon: () => {},

  isResponder: false,

  isSameAsPublished: false,
  setIsSameAsPublished: () => {},

  formStatus: {} as FORM_STATUS,
  setFormStatus: () => {},

  isLoadingForm: true,

  slug: {
    linkOption: 'default',
  },
  setSlug: () => {},
});

export default function FormBuilderContextProvider({
  children,
  initialForm,
  isResponder = false,
}: {
  children: ReactNode;
  initialForm?: Form;
  isResponder?: boolean;
}) {
  const supabase = useSupabaseBucket();
  const { formId } = useParams<{ formId: string }>();

  const [selectedElement, setSelectedElement] =
    useState<FormElementInstance | null>(null);

  const [steps, setSteps] = useState<StepSchema[]>([]);

  const [selectedStep, setSelectedStep] = useState<StepSchema>(
    {} as StepSchema,
  );

  const [theme, setTheme] = useState<Record<string, any>>({});

  const [preview, setPreview] = useState<Record<string, any>>({});

  const [tokenGate, setTokenGate] = useState<TokenGateSetting>({
    type: 'all',
    requirements: {},
  });

  const { data, isLoading } = useQuery<GetFormByIdResponse>({
    queryKey: ['form', formId],
    queryFn: async () => (await client.get(`/api/forms/${formId}`)).data,
    refetchOnWindowFocus: false,
    enabled: !isResponder,
  });
  const formData = useMemo(
    () => data ?? initialForm,
    [data, initialForm, steps],
  );

  const [form, setFormData] = useState<Form>({
    ...formData,
    sameAsPublished: true,
    teamSlug: formData?.teamSlug,
  } as Form);

  const [isSameAsPublished, setIsSameAsPublished] = useState(
    form?.sameAsPublished ?? false,
  );

  const [formStatus, setFormStatus] = useState<FORM_STATUS>(
    form?.status ? form?.status : FORM_STATUS.DRAFT,
  );

  const [slug, setSlug] = useState<Slug>({
    teamSlug: form?.teamSlug,
    formSlug: form?.slug,
    linkOption: form?.settings?.linkOption || 'default',
  });

  useEffect(() => {
    setFormData(formData as FormSchema & { sameAsPublished?: boolean });
    setSteps(formData?.steps ?? []);
    setSelectedStep(formData?.steps[0] as StepSchema);
    setTheme(formData?.settings as Record<string, string>);
    setTokenGate(
      formData?.settings?.tokenGate ?? {
        type: 'all',
        requirements: {},
      },
    );
    setIsSameAsPublished(formData?.sameAsPublished ?? false);
    setFormStatus(formData?.status ? formData?.status : FORM_STATUS.DRAFT);
    setSlug({
      teamSlug: formData?.teamSlug,
      formSlug: formData?.slug,
      linkOption: formData?.settings?.linkOption || 'default',
    });
  }, [formData]);

  const renameForm = (newName: string) => {
    setFormData((prevFormData) => ({ ...prevFormData, title: newName }));
  };

  const addField = (fieldSpecsType: FieldSpecsType, fieldType: Field) => {
    const uniqueId = nanoid();
    const field = fieldTypeToSchemaConstructor[fieldType][fieldSpecsType];
    const newField = field ? field(String(uniqueId)) : null;
    const { fieldSpecs: _fieldSpecs, ...fieldProps } =
      newField as BaseFieldSchema;

    setSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      const stepIndex = updatedSteps.findIndex(
        (step) => step.id === selectedStep?.id,
      );

      if (stepIndex !== -1) {
        const updatedFields = [
          ...updatedSteps[stepIndex]!.fields,
          fieldSpecsType === OthersType.FileUpload
            ? {
                ...fieldProps,
                fieldSpecs: {
                  ...newField?.fieldSpecs,
                  src: `${form.id}/${uniqueId}`,
                },
              }
            : newField,
        ];
        updatedSteps[stepIndex] = {
          ...updatedSteps[stepIndex],
          fields: updatedFields,
        } as StepSchema;
      }
      return updatedSteps;
    });

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: [
            ...prevSelectedStep.fields,
            fieldSpecsType === OthersType.FileUpload
              ? {
                  ...fieldProps,
                  fieldSpecs: {
                    ...newField?.fieldSpecs,
                    src: `${form.id}/${uniqueId}`,
                  },
                }
              : newField,
          ],
        }) as StepSchema,
    );
  };

  const handleDeleteField = (fieldId: string) => {
    setSteps((prevSteps) => {
      return prevSteps!.map((step) => {
        if (step.id === selectedStep?.id) {
          return {
            ...step,
            fields: step.fields.filter((field) => field.id !== fieldId),
          };
        }
        return step;
      });
    });

    setSelectedStep((prevSelectedStep) => {
      if (prevSelectedStep?.id === selectedStep?.id) {
        return {
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.filter(
            (field) => field.id !== fieldId,
          ),
        };
      }
      return prevSelectedStep;
    });
  };

  const addOptionToField = (
    stepId: string,
    fieldId: string,
    newOption: { label: string; value: string },
  ) => {
    const updateOptions = (field: BaseFieldSchema) => ({
      ...field,
      fieldSpecs: {
        ...(field.fieldSpecs as FieldSpecs),
        options: [
          ...((field.fieldSpecs as FieldSpecs).options || []),
          newOption,
        ],
      },
    });

    const isTargetField = (field: BaseFieldSchema) =>
      field.id === fieldId &&
      (field.fieldSpecs?.type === ChoiceType.CheckboxGroup ||
        field.fieldSpecs?.type === ChoiceType.RadioGroup ||
        field.fieldSpecs?.type === ChoiceType.Select);

    const updateStepFields = (step: StepSchema) => ({
      ...step,
      fields: step.fields.map((field) =>
        isTargetField(field) ? updateOptions(field) : field,
      ),
    });

    setSteps(
      (prevSteps) =>
        prevSteps.map((step) =>
          step.id === stepId ? updateStepFields(step) : step,
        ) as StepSchema[],
    );

    setSelectedStep((prevSelectedStep) =>
      prevSelectedStep?.id === stepId
        ? (updateStepFields(prevSelectedStep) as StepSchema)
        : prevSelectedStep,
    );

    setSelectedElement((prevSelectedElement) =>
      isTargetField(prevSelectedElement!)
        ? (updateOptions(prevSelectedElement!) as FormElementInstance)
        : (prevSelectedElement as FormElementInstance),
    );
  };

  const updateFieldOptionLabel = (
    stepId: string,
    fieldId: string,
    optionId: string,
    newLabel: string,
  ) => {
    setSteps(
      (prevSteps) =>
        prevSteps.map((step) => {
          if (step.id === stepId) {
            return {
              ...step,
              fields: step.fields.map((field) => {
                if (
                  field.id === fieldId &&
                  (field.fieldSpecs?.type === ChoiceType.RadioGroup ||
                    field.fieldSpecs?.type === ChoiceType.Select ||
                    field.fieldSpecs?.type === ChoiceType.CheckboxGroup)
                ) {
                  return {
                    ...field,
                    fieldSpecs: {
                      ...(field.fieldSpecs as FieldSpecs),
                      options: (field.fieldSpecs as FieldSpecs).options.map(
                        (option) =>
                          option.value === optionId
                            ? { ...option, label: newLabel }
                            : option,
                      ),
                    },
                  };
                }
                return field;
              }),
            };
          }
          return step;
        }) as StepSchema[],
    );

    setSelectedStep((prevSelectedStep) =>
      prevSelectedStep?.id === stepId
        ? ({
            ...prevSelectedStep,
            fields: prevSelectedStep.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      options: (field.fieldSpecs as FieldSpecs).options.map(
                        (option) =>
                          option.value === optionId
                            ? { ...option, label: newLabel }
                            : option,
                      ),
                    },
                  }
                : field,
            ),
          } as StepSchema)
        : prevSelectedStep,
    );

    setSelectedElement((prev) =>
      prev && prev.id === fieldId
        ? ({
            ...prev,
            fieldSpecs: {
              ...prev.fieldSpecs,
              options: (prev.fieldSpecs as FieldSpecs).options.map((option) =>
                option.value === optionId
                  ? { ...option, label: newLabel }
                  : option,
              ),
            },
          } as FormElementInstance)
        : prev,
    );
  };

  const changeQuestion = (fieldId: string, newQuestion: string) => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (step.id === selectedStep?.id) {
          return {
            ...step,
            fields: step.fields.map((field) => {
              if (field.id === fieldId) {
                return {
                  ...field,
                  label: newQuestion,
                };
              }
              return field;
            }),
          };
        }
        return step;
      });

      return updatedSteps;
    });

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep!.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  label: newQuestion,
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prevSelectedElement) =>
        ({
          ...prevSelectedElement,
          label: newQuestion,
        }) as FormElementInstance,
    );
  };

  const changeSubText = (fieldId: string, newSubText: string) => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (step.id === selectedStep?.id) {
          return {
            ...step,
            fields: step.fields.map((field) => {
              if (field.id === fieldId) {
                return {
                  ...field,
                  subText: newSubText,
                };
              }
              return field;
            }),
          };
        }
        return step;
      });

      return updatedSteps;
    });

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep!.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  subText: newSubText,
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prevSelectedElement) =>
        ({
          ...prevSelectedElement,
          subText: newSubText,
        }) as FormElementInstance,
    );
  };

  const changeImageHeight = (fieldId: string, height: number) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      height,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    height,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement((prevSelectedElement) => {
      if (prevSelectedElement?.id === fieldId) {
        return {
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement.fieldSpecs,
            height,
          },
        } as FormElementInstance;
      }
      return prevSelectedElement;
    });
  };

  const changeImageAlignment = (
    fieldId: string,
    imageAlignment: ImageAlignment,
  ) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      imageAlignment,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    imageAlignment,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement((prevSelectedElement) => {
      if (prevSelectedElement?.id === fieldId) {
        return {
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement.fieldSpecs,
            imageAlignment,
          },
        } as FormElementInstance;
      }
      return prevSelectedElement;
    });
  };

  const changeFieldType = (
    fieldId: string,
    fieldSpecsType: FieldSpecsType,
    fieldType: Field,
  ) => {
    const optionId1 = `option_${nanoid()}`;
    const optionId2 = `option_${nanoid()}`;

    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldType: fieldType,
                    fieldSpecs: {
                      ...(fieldSpecsType === ChoiceType.RadioGroup ||
                      fieldSpecsType === ChoiceType.Select
                        ? {
                            options: (field.fieldSpecs as Record<string, any>)
                              .options
                              ? (field.fieldSpecs as Record<string, any>)
                                  .options
                              : [
                                  {
                                    label: 'Option 1',
                                    value: optionId1,
                                  },
                                  {
                                    label: 'Option 2',
                                    value: optionId2,
                                  },
                                ],
                            horizontalAlignment: (
                              field.fieldSpecs as Record<string, any>
                            ).options
                              ? (field.fieldSpecs as Record<string, any>)
                                  .options
                              : false,
                            ...field.fieldSpecs,
                          }
                        : fieldSpecsType === MediaType.Image
                          ? {
                              file: {
                                name: 'Field Image',
                                preview: defaultImageUrl,
                              },
                              type: MediaType.Image,
                              dialogTriggerText: 'Change',
                              storageName: DEFAULT_STORAGE_NAME,
                              height: DEFAULT_IMAGE_HEIGHT,
                              imageAlignment: ImageAlignment.Center,
                            }
                          : !(
                                fieldSpecsType === DisplayTextType.Heading ||
                                fieldSpecsType === DisplayTextType.Paragraph
                              )
                            ? {
                                required: (
                                  field.fieldSpecs as Record<string, any>
                                ).required
                                  ? (field.fieldSpecs as Record<string, any>)
                                      .required
                                  : false,
                              }
                            : {}),
                      type: fieldSpecsType,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldType: fieldType,
                  fieldSpecs: {
                    ...(fieldSpecsType === ChoiceType.RadioGroup ||
                    fieldSpecsType === ChoiceType.Select
                      ? {
                          options: [
                            { label: 'Option 1', value: optionId1 },
                            { label: 'Option 2', value: optionId2 },
                          ],
                          horizontalAlignment: (
                            field.fieldSpecs as Record<string, any>
                          ).options
                            ? (field.fieldSpecs as Record<string, any>).options
                            : false,
                          ...field.fieldSpecs,
                        }
                      : fieldSpecsType === MediaType.Image
                        ? {
                            file: {
                              name: 'Field Image',
                              preview: defaultImageUrl,
                            },
                            type: MediaType.Image,
                            dialogTriggerText: 'Change',
                            storageName: DEFAULT_STORAGE_NAME,
                            height: DEFAULT_IMAGE_HEIGHT,
                            imageAlignment: ImageAlignment.Center,
                          }
                        : !(
                              fieldSpecsType === DisplayTextType.Heading ||
                              fieldSpecsType === DisplayTextType.Paragraph
                            )
                          ? {
                              required: (
                                field.fieldSpecs as Record<string, any>
                              ).required
                                ? (field.fieldSpecs as Record<string, any>)
                                    .required
                                : false,
                            }
                          : {}),
                    type: fieldSpecsType,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prev) =>
        ({
          ...prev,
          fieldType: fieldType,
          fieldSpecs: {
            ...prev?.fieldSpecs,
            type: fieldSpecsType,
            ...(fieldSpecsType === ChoiceType.RadioGroup ||
            fieldSpecsType === ChoiceType.Select
              ? {
                  options: [
                    { label: 'Option 1', value: optionId1 },
                    { label: 'Option 2', value: optionId2 },
                  ],
                  horizontalAlignment: (prev?.fieldSpecs as Record<string, any>)
                    .options
                    ? (prev?.fieldSpecs as Record<string, any>).options
                    : false,
                }
              : fieldSpecsType === MediaType.Image
                ? {
                    file: {
                      name: 'Field Image',
                      preview: defaultImageUrl,
                    },
                    type: MediaType.Image,
                    dialogTriggerText: 'Change',
                    storageName: DEFAULT_STORAGE_NAME,
                    height: DEFAULT_IMAGE_HEIGHT,
                    imageAlignment: ImageAlignment.Center,
                  }
                : !(
                      fieldSpecsType === DisplayTextType.Heading ||
                      fieldSpecsType === DisplayTextType.Paragraph
                    )
                  ? {
                      required: (prev?.fieldSpecs as Record<string, any>)
                        .required
                        ? (prev?.fieldSpecs as Record<string, any>).required
                        : false,
                    }
                  : {}),
          },
        }) as FormElementInstance,
    );
  };

  const changeFieldPlaceholder = (fieldId: string, newPlaceholder: string) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      placeholder: newPlaceholder,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    placeholder: newPlaceholder,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prevSelectedElement) =>
        ({
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement?.fieldSpecs,
            placeholder: newPlaceholder,
          },
        }) as FormElementInstance,
    );
  };

  const changeTextareaRows = (fieldId: string, rows: number) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      rows,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    rows,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement((prevSelectedElement) => {
      if (prevSelectedElement?.id === fieldId) {
        return {
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement.fieldSpecs,
            rows,
          },
        } as FormElementInstance;
      }
      return prevSelectedElement;
    });
  };

  const updateFieldCharLimits = (
    fieldId: string,
    minLength: number | null,
    maxLength: number | null,
  ) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      minLength,
                      maxLength,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    minLength,
                    maxLength,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement((prevSelectedElement) => {
      if (prevSelectedElement?.id === fieldId) {
        return {
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement.fieldSpecs,
            minLength,
            maxLength,
          },
        } as FormElementInstance;
      }
      return prevSelectedElement;
    });
  };

  const updateFieldSelectionLimits = (
    fieldId: string,
    minSelection: number | null,
    maxSelection: number | null,
  ) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      minSelection,
                      maxSelection,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    minSelection,
                    maxSelection,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement((prevSelectedElement) => {
      if (prevSelectedElement?.id === fieldId) {
        return {
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement.fieldSpecs,
            minSelection,
            maxSelection,
          },
        } as FormElementInstance;
      }
      return prevSelectedElement;
    });
  };

  const updateFieldOptionsOrder = (
    stepId: string,
    fieldId: string,
    options: Option[] | ((prevOptions: Option[]) => Option[]),
  ) => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (step.id === stepId) {
          const updatedFields = step.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    options: options,
                  },
                }
              : field,
          );
          return { ...step, fields: updatedFields };
        }
        return step;
      }) as StepSchema[];
      return updatedSteps;
    });

    setSelectedStep((prevSelectedStep) => {
      if (prevSelectedStep.id === stepId) {
        const updatedFields = prevSelectedStep.fields.map((field) =>
          field.id === fieldId
            ? {
                ...field,
                fieldSpecs: {
                  ...field.fieldSpecs,
                  options: options,
                },
              }
            : field,
        );
        return { ...prevSelectedStep, fields: updatedFields } as StepSchema;
      }
      return prevSelectedStep;
    });

    setSelectedElement((prevSelectedElement) => {
      if (prevSelectedElement?.id === fieldId) {
        return {
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement.fieldSpecs,
            options: options,
          },
        } as FormElementInstance;
      }
      return prevSelectedElement;
    });
  };

  const deleteFieldOption = (
    stepId: string,
    fieldId: string,
    optionId: string,
  ) => {
    setSteps(
      (prevSteps) =>
        prevSteps.map((step) => {
          if (step.id === stepId) {
            return {
              ...step,
              fields: step.fields.map((field) => {
                if (field.id === fieldId) {
                  return {
                    ...field,
                    fieldSpecs: {
                      ...(field.fieldSpecs as FieldSpecs),
                      options: (field.fieldSpecs as FieldSpecs).options.filter(
                        (option) => option.value !== optionId,
                      ),
                    },
                  };
                }
                return field;
              }),
            };
          }
          return step;
        }) as StepSchema[],
    );

    setSelectedStep((prevSelectedStep) =>
      prevSelectedStep?.id === stepId
        ? ({
            ...prevSelectedStep,
            fields: prevSelectedStep.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      options: (field.fieldSpecs as FieldSpecs).options.filter(
                        (option) => option.value !== optionId,
                      ),
                    },
                  }
                : field,
            ),
          } as StepSchema)
        : prevSelectedStep,
    );

    setSelectedElement((prev) =>
      prev && prev.id === fieldId
        ? ({
            ...prev,
            fieldSpecs: {
              ...prev.fieldSpecs,
              options: (prev.fieldSpecs as FieldSpecs).options.filter(
                (option) => option.value !== optionId,
              ),
            },
          } as FormElementInstance)
        : prev,
    );
  };

  const setAllowOtherOption = (
    stepId: string,
    fieldId: string,
    allowOther: boolean,
  ) => {
    const updateOptions = (field: BaseFieldSchema) => ({
      ...field,
      fieldSpecs: {
        ...(field.fieldSpecs as FieldSpecs),
        allowOther,
      },
    });

    const isTargetField = (field: BaseFieldSchema) =>
      field.id === fieldId &&
      (field.fieldSpecs?.type === ChoiceType.CheckboxGroup ||
        field.fieldSpecs?.type === ChoiceType.RadioGroup ||
        field.fieldSpecs?.type === ChoiceType.Select);

    const updateStepFields = (step: StepSchema) => ({
      ...step,
      fields: step.fields.map((field) =>
        isTargetField(field) ? updateOptions(field) : field,
      ),
    });

    setSteps(
      (prevSteps) =>
        prevSteps.map((step) =>
          step.id === stepId ? updateStepFields(step) : step,
        ) as StepSchema[],
    );

    setSelectedStep((prevSelectedStep) =>
      prevSelectedStep?.id === stepId
        ? (updateStepFields(prevSelectedStep) as StepSchema)
        : prevSelectedStep,
    );

    setSelectedElement((prevSelectedElement) =>
      isTargetField(prevSelectedElement!)
        ? (updateOptions(prevSelectedElement!) as FormElementInstance)
        : prevSelectedElement,
    );
  };

  const updateFieldRequiredStatus = (fieldId: string, isRequired: boolean) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      required: isRequired,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep!.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    required: isRequired,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prevSelectedElement) =>
        ({
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement?.fieldSpecs,
            required: isRequired,
          },
        }) as FormElementInstance,
    );
  };

  const changeSingleChoiceAlignment = (
    fieldId: string,
    horizontalAlignment: boolean,
  ) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      horizontalAlignment,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep!.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    horizontalAlignment,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prevSelectedElement) =>
        ({
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement?.fieldSpecs,
            horizontalAlignment,
          },
        }) as FormElementInstance,
    );
  };

  const changeImage = (file: ImageFile, fieldId: string) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...field.fieldSpecs,
                      file,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep!.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    file,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prevSelectedElement) =>
        ({
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement?.fieldSpecs,
            file,
          },
        }) as FormElementInstance,
    );
  };

  const changeFile = (
    file: { type: string; src: string; required: boolean },
    fieldId: string,
  ) => {
    setSteps((prevSteps) =>
      prevSteps.map(
        (step) =>
          ({
            ...step,
            fields: step.fields.map((field) =>
              field.id === fieldId
                ? {
                    ...field,
                    fieldSpecs: {
                      ...file,
                      ...field.fieldSpecs,
                    },
                  }
                : field,
            ),
          }) as StepSchema,
      ),
    );

    setSelectedStep(
      (prevSelectedStep) =>
        ({
          ...prevSelectedStep,
          fields: prevSelectedStep!.fields.map((field) =>
            field.id === fieldId
              ? {
                  ...field,
                  fieldSpecs: {
                    ...file,
                    ...field.fieldSpecs,
                  },
                }
              : field,
          ),
        }) as StepSchema,
    );

    setSelectedElement(
      (prevSelectedElement) =>
        ({
          ...prevSelectedElement,
          fieldSpecs: {
            ...file,
            ...prevSelectedElement?.fieldSpecs,
          },
        }) as FormElementInstance,
    );
  };

  const duplicateField = async (
    formId: string,
    fieldToDuplicate: FormElementInstance,
  ): Promise<FormElementInstance> => {
    const uniqueId = nanoid();

    if (
      fieldToDuplicate.fieldSpecs?.type === MediaType.Image &&
      (fieldToDuplicate.fieldSpecs?.file as ImageFile)?.preview &&
      (fieldToDuplicate.fieldSpecs?.file as ImageFile)?.preview !==
        defaultImageUrl
    ) {
      const imageFile = fieldToDuplicate.fieldSpecs.file;

      try {
        // Clone the image in Supabase storage
        const { data, error } = await supabase.storage
          .from(DEFAULT_STORAGE_NAME)
          .copy(
            `${formId}/${fieldToDuplicate?.id}`,
            `${formId}/${fieldToDuplicate?.id}-${uniqueId}`,
          );

        if (error) {
          console.error('Error cloning image:', error.message);
          throw new Error('Failed to clone image');
        }

        // Update the field with the new cloned image URL
        (fieldToDuplicate.fieldSpecs?.file as ImageFile).preview =
          `${supabaseStorageUrl}/${data?.path}` ||
          (imageFile as ImageFile).preview;
      } catch (error) {
        console.error('Error cloning image:', error);
        throw new Error('Failed to clone image');
      }
    }

    // Handle option duplication for specific field types
    const duplicateFieldOptions = (field: FormElementInstance): Option[] => {
      if (
        field.fieldSpecs?.type === ChoiceType.CheckboxGroup ||
        field.fieldSpecs?.type === ChoiceType.RadioGroup ||
        field.fieldSpecs?.type === ChoiceType.Select
      ) {
        const options = (field.fieldSpecs?.options as Option[]) || [];
        return options.map((option) => ({
          ...option,
          id: `option-${uniqueId}`, // Ensure each option has a unique ID
        }));
      }
      return [];
    };

    return {
      ...fieldToDuplicate,
      id: `${fieldToDuplicate?.id}-${uniqueId}`,
      fieldSpecs: !(
        fieldToDuplicate.fieldSpecs?.type === DisplayTextType.Heading ||
        fieldToDuplicate.fieldSpecs?.type === DisplayTextType.Paragraph
      )
        ? {
            ...fieldToDuplicate.fieldSpecs,
            ...(fieldToDuplicate.fieldSpecs?.type ===
              ChoiceType.CheckboxGroup ||
            fieldToDuplicate.fieldSpecs?.type === ChoiceType.RadioGroup ||
            fieldToDuplicate.fieldSpecs?.type === ChoiceType.Select
              ? { options: duplicateFieldOptions(fieldToDuplicate) }
              : {}),
          }
        : {
            ...fieldToDuplicate.fieldSpecs,
            type: fieldToDuplicate.fieldSpecs.type as DisplayTextType,
          },
    } as FormElementInstance;
  };

  const handleDuplicateField = async (
    formId: string,
    field: FormElementInstance,
  ) => {
    const duplicatedField = await duplicateField(formId, field);

    if (!duplicatedField) return;

    const updatedSteps = steps.map((step) => {
      if (step.id === selectedStep?.id) {
        const fields = [...step.fields];
        const index = fields.findIndex((f) => f.id === field.id);
        if (index !== -1) {
          fields.splice(index + 1, 0, duplicatedField);
        }
        return {
          ...step,
          fields,
        };
      }
      return step;
    });

    setSteps(updatedSteps as StepSchema[]);

    setSelectedStep((prevSelectedStep) => {
      if (prevSelectedStep?.id === selectedStep?.id) {
        const fields = [...prevSelectedStep.fields];
        const index = fields.findIndex((f) => f.id === field.id);
        if (index !== -1) {
          fields.splice(index + 1, 0, duplicatedField);
        }
        return {
          ...prevSelectedStep,
          fields,
        } as StepSchema;
      }
      return prevSelectedStep as StepSchema;
    });
  };

  const addStep = ({
    name,
    type,
    description,
    fields = [],
  }: {
    name: string;
    type: string;
    description?: string;
    fields?: BaseFieldSchema[];
  }) => {
    const uniqueId = nanoid();
    const newStep = createBaseStep({
      id: uniqueId,
      name: name,
      type: type,
      description: description,
      fields,
      defaultNextStep: steps[steps.length]?.id || '',
    });

    if (type === StepType.WELCOME) {
      // Add welcome step to the beginning
      setSteps((prevSteps) => [newStep, ...prevSteps]);
      return;
    }

    setSteps((prevSteps) => {
      const endingIndex = prevSteps.findIndex(
        (step) => step.type === StepType.ENDING,
      );

      const updatedSteps = [...prevSteps];
      if (endingIndex === -1) {
        // Append new step to the end
        updatedSteps.push(newStep);
      } else {
        // Insert new step before the ending step
        updatedSteps.splice(endingIndex, 0, newStep);
      }

      return updatedSteps;
    });
  };

  const duplicateStep = (stepToDuplicate: StepSchema) => {
    const uniqueId = nanoid();
    return {
      ...stepToDuplicate,
      id: `${stepToDuplicate?.id}-${uniqueId}`,
      fields: stepToDuplicate.fields.map((field) => ({
        ...field,
        id: nanoid(),
      })),
    };
  };

  const handleDuplicateStep = (step: StepSchema) => {
    const duplicatedStep = duplicateStep(step);

    setSteps((prevSteps) => {
      const index = prevSteps.findIndex((s) => s.id === step.id);
      if (index !== -1) {
        const updatedSteps = [...prevSteps];
        updatedSteps.splice(index + 1, 0, duplicatedStep);
        return updatedSteps;
      }
      return prevSteps;
    });

    setSelectedStep(duplicatedStep);
  };

  const renameStep = (stepId: string, newName: string) => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (step.id === stepId) {
          return { ...step, name: newName };
        }
        return step;
      });
      return updatedSteps;
    });
    setSelectedStep((prevSelectedStep) => {
      if (prevSelectedStep?.id === stepId) {
        return { ...prevSelectedStep, name: newName };
      }
      return prevSelectedStep;
    });
  };

  const deleteStep = (stepId: string) => {
    setSteps((prevSteps) => {
      const index = prevSteps.findIndex((step) => step.id === stepId);
      if (index === -1) return prevSteps;

      const updatedSteps = prevSteps.filter((step) => step.id !== stepId);
      if (updatedSteps.length === 0) return updatedSteps; // Handle case where all steps are deleted

      // Determine the new selected step index
      const newSelectedStepIndex = index === 0 ? 0 : index - 1;
      const newSelectedStep = updatedSteps[newSelectedStepIndex];

      if (newSelectedStep) {
        setSelectedStep(newSelectedStep);
      }
      if (prevSteps[index]?.type === StepType.WELCOME) {
        setTheme((prevTheme) => {
          const cloned = { ...prevTheme };
          delete cloned[UploadImageType.CoverImage];
          return cloned;
        });
      }
      return updatedSteps;
    });
  };

  const toggleEndingIcon = (stepId: string, v: boolean) => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (step.id === stepId) {
          return {
            ...step,
            fields: step.fields.map((field) => {
              if (field.fieldType === 'ending') {
                return {
                  ...field,
                  fieldSpecs: {
                    ...field.fieldSpecs,
                    hideIcon: v,
                  },
                };
              }
              return field;
            }),
          };
        }
        return step;
      });
      return updatedSteps;
    });
    setSelectedStep((prevSelectedStep) => {
      if (prevSelectedStep?.id === stepId) {
        return {
          ...prevSelectedStep,
          fields: prevSelectedStep.fields.map((field) => {
            if (field.fieldType === 'ending') {
              return {
                ...field,
                fieldSpecs: {
                  ...field.fieldSpecs,
                  hideIcon: v,
                },
              };
            }
            return field;
          }),
        };
      }
      return prevSelectedStep;
    });
    setSelectedElement((prevSelectedElement) => {
      if (prevSelectedElement?.fieldType === 'ending') {
        return {
          ...prevSelectedElement,
          fieldSpecs: {
            ...prevSelectedElement.fieldSpecs,
            hideIcon: v,
          },
        };
      }
      return prevSelectedElement;
    });
  };

  return (
    <FormBuilderContext.Provider
      value={{
        form,
        selectedElement,
        setSelectedElement,

        selectedStep,
        setSelectedStep,

        steps,
        setSteps,

        theme,
        setTheme,

        preview,
        setPreview,

        renameForm,

        addField,
        handleDeleteField,
        addOptionToField,
        updateFieldOptionLabel,
        changeFieldType,
        updateFieldRequiredStatus,
        changeSingleChoiceAlignment,
        changeFieldPlaceholder,
        changeTextareaRows,
        updateFieldOptionsOrder,
        setAllowOtherOption,
        deleteFieldOption,
        changeQuestion,
        changeSubText,
        changeImageHeight,
        changeImageAlignment,
        changeImage,
        changeFile,
        handleDuplicateField,
        updateFieldCharLimits,
        updateFieldSelectionLimits,

        addStep,
        handleDuplicateStep,
        renameStep,
        deleteStep,

        tokenGate,
        setTokenGate,

        toggleEndingIcon,

        isResponder,

        isSameAsPublished,
        setIsSameAsPublished,

        formStatus,
        setFormStatus,

        isLoadingForm: isLoading,

        slug,
        setSlug,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
}
