'use client';

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { SelectProps } from '@radix-ui/react-select';
import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Input } from '~/components/ui/input';
import { ScrollArea } from '~/components/ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui/select';
import { FieldSpecs, otherOptionKey } from '~/constants/fields';
import { Option } from '~/constants/fields';
import { cn } from '~/lib/utils';

import { DragHandle } from '../../Builder/components/common/buttons/DragHandle';
import AddOptionButton from '../../common/AddOptionButton';
import TrashV2 from '../../common/TrashV2';

type DropdownSelectProps = SelectProps & {
  id?: string;
  options: Option[];
  allowOther?: boolean;
  placeHolder?: string;
  preFetch?: () => Promise<Option[]>;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: string) => void;
  classNames?: {
    font?: string;
  };
  className?: string;
  theme?: Record<string, any>;
  isBuilderMode?: boolean;
};

const DropdownSelect = forwardRef<HTMLButtonElement, DropdownSelectProps>(
  (
    {
      id,
      options: initialOptions = [],
      allowOther = false,
      placeHolder,
      preFetch,
      onChange,
      value,
      defaultValue,
      theme = {},
      classNames: { font } = {},
      isBuilderMode = false,
      ...props
    },
    ref,
  ) => {
    const {
      selectedElement,
      selectedStep,
      updateFieldOptionsOrder,
      deleteFieldOption,
      updateFieldOptionLabel,
    } = useFormBuilder();
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [hoveredOptionId, setHoveredOptionId] = useState<string | null>(null);
    const [internalValue, setInternalValue] = useState(value ?? defaultValue);
    const [options, setOptions] = useState<Option[]>(initialOptions);
    const [isOther, setIsOther] = useState(() => {
      if (!allowOther) return false;
      if (typeof internalValue !== 'string') return false;
      return !options.some((option) => option.value === internalValue);
    });

    const isSelected = selectedElement?.id === id;
    const field = selectedStep.fields.find((field) => field.id === id);

    const onOptionChange = (value: string) => {
      if (value === otherOptionKey) {
        setInternalValue('');
        onChange?.('');
        setIsOther(true);
      } else {
        setInternalValue(value);
        onChange?.(value);
        setIsOther(false);
      }
    };

    const onOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInternalValue(e.target.value);
      onChange?.(e.target.value);
    };

    // For controlled components
    useEffect(() => {
      if (value === undefined) return;
      const isOther = !options.some((option) => option.value === value);
      setInternalValue((prev) => (prev === value ? prev : value));
      // We don't want to change the value to an option if other is selected
      setIsOther((prev) => prev || isOther);
    }, [value]);

    useEffect(() => {
      if (selectedStep?.fields) {
        if ((field?.fieldSpecs as FieldSpecs)?.options) {
          setOptions((field?.fieldSpecs as FieldSpecs).options);
          setIsOther(
            typeof internalValue === 'string' &&
              !(field?.fieldSpecs as FieldSpecs).options.some(
                (option) => option.value === internalValue,
              ),
          );
        }
      }
    }, [selectedStep, internalValue]);

    useEffect(() => {
      if (!preFetch) return;

      const handleFetchOptions = async () => {
        try {
          const fetchedOptions = await preFetch();
          setOptions(fetchedOptions);
          setIsOther(
            !fetchedOptions.some((option) => option.value === internalValue),
          );
        } catch (error) {
          console.log('[ERROR] Fetching options', error);
          setOptions([
            {
              label: 'Error fetching options',
              value: 'error',
              disabled: true,
            },
          ]);
          setInternalValue('error');
        }
      };

      handleFetchOptions();
    }, [preFetch]);

    const handleFocus = (
      event: React.FocusEvent<HTMLButtonElement | HTMLInputElement>,
    ) => {
      event.target.style.border = theme?.primary
        ? `1.5px solid ${theme.primary}`
        : '1.5px solid rgba(134, 219, 46, 0.8)';
      event.target.style.boxShadow = theme?.primary
        ? `0px 0px 4px 0px  ${theme?.primary}`
        : '0px 0px 4px 0px rgba(134, 219, 46, 1)';
      event.target.style.backgroundColor = 'rgba(255, 255, 255, 1)';
    };

    const handleBlur = (
      event: React.FocusEvent<HTMLButtonElement | HTMLInputElement>,
    ) => {
      setSelectedOption(null);
      event.target.style.border = '1.5px solid rgba(0, 0, 0, 0.1)';
      event.target.style.boxShadow = 'none';
    };

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) return;

      const updatedOptions = Array.from(options) as Option[];
      const [movedOption] = updatedOptions.splice(result.source.index, 1);
      updatedOptions.splice(result.destination.index, 0, movedOption!);

      setOptions(updatedOptions);
      updateFieldOptionsOrder(selectedStep?.id, id!, updatedOptions);
    };

    const handleLabelChange = (optionId: string, newLabel: string) => {
      setSelectedOption(null);
      if (!newLabel) {
        updateFieldOptionLabel(selectedStep?.id, id as string, optionId, '   ');
      } else {
        updateFieldOptionLabel(
          selectedStep?.id,
          id as string,
          optionId,
          newLabel,
        );
      }
    };

    const handleSelectedOption = (optionId: string) => {
      setSelectedOption(null);
      const selected = options.find((option) => option.value === optionId);
      if (selected) setSelectedOption(selected);
    };

    const handleDeleteOption = (optionId: string) => {
      deleteFieldOption(selectedStep?.id, id!, optionId);
      setOptions((prevOptions) =>
        prevOptions.filter((option) => option.value !== optionId),
      );
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLSpanElement>) => {
      e.preventDefault();

      // Get text from the clipboard
      const text = e.clipboardData.getData('text/plain');

      // Insert the plain text at the cursor position
      document.execCommand('insertText', false, text);
    };

    const displayOptions = allowOther
      ? [...options, { value: otherOptionKey, label: 'Other' }]
      : options;

    const isSafari = useMemo(() => {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }, [window.navigator.userAgent]);

    return (
      <>
        <Select
          {...props}
          onValueChange={onOptionChange}
          value={isOther ? otherOptionKey : internalValue}
        >
          <SelectTrigger
            className={cn(
              'focus:ring-0 focus:ring-offset-0 shadow h-[46px] text-left w-full overflow-clip break-word',
              props.className,
            )}
            style={{
              color: theme?.answers ?? '#000000',
            }}
            ref={ref}
            type="button"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <SelectValue placeholder={placeHolder} />
          </SelectTrigger>
          <SelectContent
            // Max height is between 30lvh on larger screen or min between 96*4px and available height
            className="-px-1 max-h-[max(30lvh,min(calc(96*4px),var(--radix-select-content-available-height)))] max-w-[var(--radix-select-trigger-width)] overflow-y-auto overflow-x-clip"
            hideScrollUpButton
            hideScrollDownButton
          >
            <ScrollArea
              className="relative h-full w-[calc(100%-4px)] py-0.5 pl-1.5 pr-1"
              style={{
                height: isSafari ? 'max-content' : 'auto',
                maxHeight: isSafari
                  ? 'max(30lvh,min(calc(96*4px),calc(var(--radix-select-content-available-height) - 40px)))'
                  : undefined,
                overflowY: isSafari ? 'auto' : undefined,
              }}
            >
              {options.map(({ value, label }, index) => (
                <SelectItem
                  key={index}
                  value={String(value)}
                  className={cn(
                    'flex min-h-11 w-full items-center px-3 text-left text-lg font-normal',
                    font,
                  )}
                  style={{
                    color: theme?.answers ?? '#000000',
                  }}
                >
                  <span
                    className={cn(
                      font,
                      'w-[calc(100%-4px)] break-word text-lg font-normal',
                    )}
                  >
                    {label}
                  </span>
                </SelectItem>
              ))}
              {allowOther && (
                <SelectItem
                  key={otherOptionKey}
                  value={otherOptionKey}
                  className={cn(
                    'flex min-h-11 w-full items-center px-3 text-left text-lg font-normal',
                    font,
                  )}
                  style={{
                    color: theme?.answers ?? '#000000',
                  }}
                >
                  <span
                    className={cn(
                      font,
                      'w-[calc(100%-4px)] break-word text-lg font-normal',
                    )}
                  >
                    Other
                  </span>
                </SelectItem>
              )}
            </ScrollArea>
          </SelectContent>
          {allowOther && isOther && (
            <>
              <Input
                className={cn(
                  'py-[13.5px] text-lg shadow focus:ring-0 focus:ring-offset-0',
                  props.className,
                  font,
                )}
                style={{
                  color: theme?.answers ?? '#000000',
                }}
                type="text"
                value={internalValue}
                onChange={onOtherChange}
                placeholder="Type your answer here"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </>
          )}
        </Select>

        {isBuilderMode && isSelected && (
          <>
            {/* <Separator className='w-full h-[1px] my-4 bg-black/5 text-black/5' /> */}
            <div className="mt-4 w-full space-y-2">
              <div className="h-fit p-0 flex items-center justify-start space-x-2">
                <h2
                  className="text-base font-medium leading-6"
                  style={{
                    color: theme?.primary || 'rgba(121, 201, 38, 1)',
                  }}
                >
                  Options
                </h2>
              </div>

              <div className="w-full space-y-2">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="droppable-dropdown-options"
                    type="group"
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {displayOptions.map(
                          ({ label, value }, index) =>
                            value !== otherOptionKey && (
                              <Draggable
                                key={value}
                                draggableId={'draggable-' + value}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="relative flex items-center"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                    }}
                                    onFocus={() => handleSelectedOption(value)}
                                    onMouseEnter={() =>
                                      setHoveredOptionId(value)
                                    }
                                    onMouseLeave={() =>
                                      setHoveredOptionId(null)
                                    }
                                  >
                                    {provided && (
                                      <DragHandle
                                        {...provided.dragHandleProps}
                                        className="mr-1.5 mt-1 max-w-[12px]"
                                        containerClassName="max-w-[12px] mr-1.5 mt-1"
                                      />
                                    )}
                                    <div
                                      style={{
                                        cursor: 'pointer',
                                        color:
                                          theme?.answers ??
                                          'rgba(71, 71, 76, 1)',
                                        border:
                                          selectedOption?.value === value
                                            ? theme?.primary
                                              ? `1px solid ${theme?.primary}`
                                              : '1px solid rgba(134, 219, 46, 1)'
                                            : '1px solid rgba(0, 0, 0, 0.10)',
                                        boxShadow:
                                          selectedOption?.value === value
                                            ? theme?.primary
                                              ? `0px 0px 4px 0px ${theme?.primary}`
                                              : '0px 0px 4px 0px rgba(134, 219, 46, 1)'
                                            : '',
                                      }}
                                      className={cn(
                                        'mt-2 flex w-full rounded-lg border-1.5 border-black/10 bg-white px-3 py-2.5 text-base font-normal shadow-sm focus:translate-x-0 focus:translate-y-0 focus:transform focus-visible:ring-0 focus-visible:ring-offset-0',
                                        font,
                                        theme?.radius
                                          ? theme?.radius
                                          : 'rounded-md',
                                        theme?.primary ? '' : '',
                                      )}
                                    >
                                      <span
                                        contentEditable={
                                          isBuilderMode && label !== 'Other'
                                        }
                                        className="w-[92%] break-word text-lg outline-none"
                                        onBlur={(e) =>
                                          handleLabelChange(
                                            value,
                                            e.target.textContent || '',
                                          )
                                        }
                                        onPaste={handlePaste}
                                      >
                                        {label}
                                      </span>
                                    </div>
                                    {(selectedOption?.value === value ||
                                      hoveredOptionId === value) && (
                                      <TrashV2
                                        className="absolute right-4 top-[22px] cursor-pointer"
                                        onClick={() =>
                                          handleDeleteOption(value)
                                        }
                                      />
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ),
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {displayOptions.map(
                  ({ label, value }) =>
                    value === otherOptionKey && (
                      <div key={value} className="flex items-center">
                        <DragHandle
                          className="mr-1.5 mt-1 max-w-[12px]"
                          containerClassName="max-w-[12px] mr-1.5 mt-1"
                        />
                        <Input
                          style={{
                            cursor: 'default',
                            color: theme?.answers ?? 'rgba(71, 71, 76, 1)',
                            border:
                              selectedOption?.value === value
                                ? theme?.primary
                                  ? `1px solid ${theme?.primary}`
                                  : '1px solid rgba(134, 219, 46, 1)'
                                : '1px solid rgba(0, 0, 0, 0.10)',
                            boxShadow:
                              selectedOption?.value === value
                                ? theme?.primary
                                  ? `0px 0px 4px 0px ${theme?.primary}`
                                  : '0px 0px 4px 0px rgba(134, 219, 46, 1)'
                                : '',
                          }}
                          defaultValue={label as string}
                          readOnly
                          className={cn(
                            'flex w-full rounded-lg border-1.5 border-black/10 px-3 py-2.5 text-lg font-normal shadow-sm focus:translate-x-0 focus:translate-y-0 focus:transform focus-visible:ring-0 focus-visible:ring-offset-0',
                            font,
                            theme?.radius ? theme?.radius : 'rounded-md',
                            theme?.primary ? '' : '',
                          )}
                        />
                      </div>
                    ),
                )}
                <AddOptionButton
                  fieldId={id!}
                  label="Add"
                  className="mt-2"
                  setOptions={setOptions}
                  options={options}
                  classNames={font ? { font } : undefined}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

export default DropdownSelect;
