import React, { FC, HTMLAttributes, useState } from 'react';

type TrashV2IconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const TrashV2: FC<TrashV2IconProps> = ({
  size = 18,
  color = 'currentColor',
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const getColor = () => {
    if (isFocused) return 'rgba(199, 199, 202, 1)';
    if (isHovered) return 'rgba(220, 220, 221, 1)';
    return 'rgba(142, 142, 146, 1)';
  };

  return (
    <svg
      width={size}
      height={(size * 17) / 15}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsFocused(true)}
      onMouseUp={() => setIsFocused(false)}
      {...props}
    >
      <path
        d="M5 5.2293L15 7.33232"
        stroke={getColor() ?? color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.62109 3.55795L12.3711 4.34659"
        stroke={getColor() ?? color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.71191 9.40563H13.3781V16.0276C13.3781 16.5046 12.9491 16.8913 12.4198 16.8913H6.67018C6.14093 16.8913 5.71191 16.5046 5.71191 16.0276V9.40563Z"
        fill={getColor() ?? color}
        stroke={getColor() ?? color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashV2;
