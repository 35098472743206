import React, { useState } from 'react';
import Plus from '~/app/_components/common/Plus';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Button } from '~/components/ui/button';
import { Option } from '~/constants/fields';
import { cn } from '~/lib/utils';
import { hexToRGBA } from '~/utils/hexToRGBA';
import nanoid from '~/utils/nanoid';

type AddOptionButtonProps = {
  fieldId: string;
  label: string;
  setOptions: React.Dispatch<React.SetStateAction<Option[]>>;
  options: Option[];
  style?: React.CSSProperties;
  className?: string;
  classNames?: { font: string };
  isSystemButton?: boolean;
} & Record<string, any>;

const AddOptionButton: React.FC<AddOptionButtonProps> = ({
  fieldId,
  label,
  setOptions,
  options,
  style,
  className = '',
  classNames: { font } = {},
  isSystemButton,
}) => {
  const { addOptionToField, selectedStep, selectedElement, theme } =
    useFormBuilder();
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleAddOption = () => {
    const stepId = selectedStep.id;
    const newOption = { label: 'New Option', value: `option_${nanoid()}` };
    if (fieldId === selectedElement?.id) {
      addOptionToField(stepId, fieldId, newOption);
      setOptions([...options, newOption]);
    }
  };

  const getButtonColor = (): string => {
    if (isSystemButton && isHovered) {
      return 'rgba(167, 229, 103, 1)';
    } else if (!isSystemButton) {
      if (theme?.primary) {
        return isHovered
          ? hexToRGBA(theme?.primary, 1)
          : isClicked
            ? hexToRGBA(theme?.primary, 0.8)
            : hexToRGBA(theme?.primary, 1);
      } else {
        return isHovered
          ? 'rgba(167, 229, 103, 1)'
          : isClicked
            ? 'rgba(121, 201, 38, 1)'
            : 'rgba(121, 201, 38, 1)';
      }
    } else return 'rgba(121, 201, 38, 1)';
  };

  const getButtonStyles = (): React.CSSProperties => {
    const defaultStyles: React.CSSProperties = {
      backgroundImage:
        isHovered || isClicked
          ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), linear-gradient(0deg, rgba(196, 249, 140, 0.1), rgba(196, 249, 140, 0.1))'
          : '',
      backgroundColor: isHovered ? '' : 'rgba(0, 0, 0, 0.02)',
      borderColor: isHovered
        ? 'rgba(184, 234, 133, 1)'
        : 'rgba(134, 219, 46, 1)',
      color: isHovered
        ? 'rgba(167, 229, 103, 1)'
        : isClicked
          ? 'rgba(121, 201, 38, 1)'
          : '',
    };

    function getCustomButtonColor() {
      if (!theme?.primary) {
        return isHovered
          ? 'rgba(167, 229, 103, 1)'
          : isClicked
            ? 'rgba(121, 201, 38, 1)'
            : 'rgba(121, 201, 38, 1)';
      } else {
        return isHovered
          ? hexToRGBA(theme?.primary, 1)
          : isClicked
            ? hexToRGBA(theme?.primary, 0.8)
            : hexToRGBA(theme?.primary, 1);
      }
    }

    if (!isSystemButton) {
      const primaryColor = theme?.primary ?? 'rgba(121, 201, 38, 1)';
      return {
        border: `1px dashed ${primaryColor}`,
        backgroundColor: isHovered ? '' : 'rgba(0, 0, 0, 0.02)',
        backgroundImage:
          isHovered || isClicked
            ? `linear-gradient(0deg, ${hexToRGBA(primaryColor, 0.1)}, ${hexToRGBA(primaryColor, 0.1)}), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`
            : '',
        color: getCustomButtonColor(),
        ...style,
      };
    }

    return {
      ...defaultStyles,
      ...style,
    };
  };

  return (
    <div className="max-h-7.5 flex cursor-pointer items-center gap-3">
      <Button
        variant="outline"
        type="button"
        className={cn(
          className,
          font,
          isSystemButton && 'border-custom text-custom-dark-green',
          'flex h-full cursor-pointer items-center gap-0 rounded-md border-1 border-dashed bg-inherit py-0.5 pl-1.5 pr-2 text-sm font-medium leading-4',
        )}
        onClick={handleAddOption}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={() => {
          setIsHovered(false);
          setIsClicked(true);
        }}
        onMouseUp={() => setIsClicked(false)}
        style={getButtonStyles()}
      >
        <Plus size={22} color={getButtonColor()} />
        {label}
      </Button>
    </div>
  );
};

export default AddOptionButton;
