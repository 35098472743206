import { FC, HTMLAttributes } from 'react';

type RequirementNativeProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const RequirementNative: FC<RequirementNativeProps> = ({
  size = 25,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.4375 6.45801C0.4375 3.1443 3.12379 0.458008 6.4375 0.458008H18.4375C21.7512 0.458008 24.4375 3.1443 24.4375 6.45801V18.458C24.4375 21.7717 21.7512 24.458 18.4375 24.458H6.4375C3.12379 24.458 0.4375 21.7717 0.4375 18.458V6.45801Z"
        fill="white"
        fill-opacity="0.15"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9523 7.91976L12.1229 3.97681L5.27336 7.93137L12.1028 11.8767L18.9523 7.91976ZM19.7145 9.21173L12.8728 13.1642L12.8712 21.0768L19.7145 17.1259V9.21173ZM11.3712 21.0749L11.3728 13.1873L4.53125 9.23497V17.1259L11.3712 21.0749Z"
        fill="#B4B4B7"
      />
    </svg>
  );
};

export default RequirementNative;
