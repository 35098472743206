import { FC, HTMLAttributes } from 'react';

type ImageProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Image: FC<ImageProps> = ({
  size = 41,
  shouldBlend = false,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1903_27139)">
        <rect x="5.5" y="4" width="30" height="30" rx="6" fill="#CFF8F6" />
        <rect
          x="6"
          y="4.5"
          width="29"
          height="29"
          rx="5.5"
          stroke="#219099"
          stroke-opacity="0.5"
        />
        <path
          d="M23.7324 20.3215L15.3375 27.5001H27.6789C28.673 27.5001 29.4789 26.6942 29.4789 25.7001V23.377C29.4789 23.0777 29.3448 22.794 29.1134 22.6041L26.3011 20.2957C25.5518 19.6806 24.4692 19.6915 23.7324 20.3215Z"
          fill="#219099"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.6667 21.0686C25.292 20.7611 24.7507 20.7665 24.3823 21.0815L18.0457 26.5001H27.6789C28.1208 26.5001 28.4789 26.1419 28.4789 25.7001L28.4789 23.377L25.6667 21.0686ZM23.0825 19.5615C24.1877 18.6164 25.8116 18.6001 26.9356 19.5227L29.7479 21.8311C30.2107 22.211 30.4789 22.7783 30.4789 23.377V25.7001C30.4789 27.2465 29.2253 28.5001 27.6789 28.5001H15.3376C14.9189 28.5001 14.5445 28.2393 14.3995 27.8465C14.2545 27.4537 14.3695 27.0122 14.6877 26.7401L23.0825 19.5615Z"
          fill="#219099"
        />
        <path
          d="M12.2111 18.0929L11.6417 19.0453C11.549 19.2004 11.5 19.3778 11.5 19.5584L11.5 25.7C11.5 26.6941 12.3059 27.5 13.3 27.5H24.8613L17.1358 17.7671C15.8318 16.1241 13.2874 16.2925 12.2111 18.0929Z"
          fill="#219099"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.3526 18.3888C15.4832 17.2935 13.7869 17.4057 13.0694 18.606L12.5 19.5585L12.5 25.7001C12.5 26.1419 12.8582 26.5001 13.3 26.5001H22.7908L16.3526 18.3888ZM11.3528 17.5798C12.7878 15.1793 16.1803 14.9548 17.9191 17.1454L25.6446 26.8784C25.8831 27.1789 25.9287 27.5894 25.7618 27.935C25.5949 28.2805 25.245 28.5001 24.8613 28.5001H13.3C11.7536 28.5001 10.5 27.2464 10.5 25.7L10.5 19.5585C10.5 19.1971 10.5979 18.8425 10.7834 18.5323L11.6417 19.0454L10.7834 18.5323L11.3528 17.5798Z"
          fill="#219099"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.3526 18.3888C15.4832 17.2935 13.7869 17.4057 13.0694 18.606L12.5 19.5585L12.5 25.7001C12.5 26.1419 12.8582 26.5001 13.3 26.5001H22.7908L16.3526 18.3888ZM11.3528 17.5798C12.7878 15.1793 16.1803 14.9548 17.9191 17.1454L25.6446 26.8784C25.8831 27.1789 25.9287 27.5894 25.7618 27.935C25.5949 28.2805 25.245 28.5001 24.8613 28.5001H13.3C11.7536 28.5001 10.5 27.2464 10.5 25.7L10.5 19.5585C10.5 19.1971 10.5979 18.8425 10.7834 18.5323L11.6417 19.0454L10.7834 18.5323L11.3528 17.5798Z"
          fill="#219099"
        />
        <path
          d="M24.9823 28.4961L16.3526 18.3888C15.4832 17.2935 13.787 17.4057 13.0694 18.606L12.5 19.5585L12.4845 15.5404C14.1412 14.3883 16.5348 14.6162 17.9036 16.3406L28.0945 28.4961L24.9823 28.4961Z"
          fill="#CFF8F6"
        />
        <path
          d="M27.5 9.78979C27.5 9.23751 27.0523 8.78979 26.5 8.78979C25.9477 8.78979 25.5 9.23751 25.5 9.78979V11.7898H23.5C22.9477 11.7898 22.5 12.2375 22.5 12.7898C22.5 13.3421 22.9477 13.7898 23.5 13.7898H25.5V15.7898C25.5 16.3421 25.9477 16.7898 26.5 16.7898C27.0523 16.7898 27.5 16.3421 27.5 15.7898V13.7898H29.5C30.0523 13.7898 30.5 13.3421 30.5 12.7898C30.5 12.2375 30.0523 11.7898 29.5 11.7898H27.5V9.78979Z"
          fill="#219099"
        />
        <path
          d="M13.5 11.4999C12.8931 11.4999 12.5 11.951 12.5 12.3888V25.611C12.5 26.0489 12.8931 26.4999 13.5 26.4999H27.5C28.1069 26.4999 28.5 26.0489 28.5 25.611V18.3847C28.5 17.8324 28.9477 17.3847 29.5 17.3847C30.0523 17.3847 30.5 17.8324 30.5 18.3847V25.611C30.5 27.2596 29.3047 28.5585 27.5 28.4999H13.5C11.8978 28.4999 10.5 27.2596 10.5 25.611V12.3888C10.5 10.7403 11.8978 9.49994 13.5 9.49994H20.9127C21.465 9.49994 21.9127 9.94765 21.9127 10.4999C21.9127 11.0522 21.465 11.4999 20.9127 11.4999H13.5Z"
          fill="#219099"
        />
      </g>
      {shouldBlend && (
        <defs>
          <filter
            id="filter0_d_1903_27139"
            x="0.5"
            y="0"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.79475 0 0 0 0 0.981674 0 0 0 0 0.99126 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1903_27139"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1903_27139"
              result="shape"
            />
          </filter>
        </defs>
      )}
    </svg>
  );
};

export default Image;
