import { FormResponse, PaginatedResponse } from '@formo/types';
import { z } from 'zod';

import { filterRequest, paginationRequest } from './common';

export const getResponsesOfFormRequest = z.object({
  params: z.object({
    formId: z.string(),
    teamId: z.string(),
  }),
  query: z.object({
    ...filterRequest.shape,
    ...paginationRequest.shape,
  }),
});
export type GetResponsesOfFormRequest = z.infer<
  typeof getResponsesOfFormRequest
>;
export type GetResponsesOfFormResponse = PaginatedResponse<FormResponse>;

export const createResponseRequest = z.object({
  params: z.object({
    formId: z.string(),
  }),
  body: z.object({
    stepIds: z.array(z.string()),
    data: z.record(z.any()),
  }),
});
export type CreateResponseRequest = z.infer<typeof createResponseRequest>;
export type CreateResponseResponse = FormResponse;