import * as jwt from 'jsonwebtoken';

export type GenerateSdkJWTBody = {
  origin: string;
  project_id: string;
};

export const generateSdkJWT = (
  body: GenerateSdkJWTBody,
  secret: string,
  options?: jwt.SignOptions,
) => {
  return jwt.sign(body, secret, options);
};

export const verifySdkJWT = (
  token: string,
  secret: string,
  options?: jwt.VerifyOptions,
) => {
  return jwt.verify(token, secret, options);
};

if (require.main === module) {
  const args = process.argv.slice(2);
  const origin = args[0];
  const projectId = args[1];
  const secret = process.env.SDK_JWT_SECRET;

  if (!origin || !projectId || !secret) {
    console.error(
      'Usage: SDK_JWT_SECRET=<secret> ts-node sdk.ts <origin> <project_id>',
    );
    process.exit(1);
  }

  const token = generateSdkJWT({ origin, project_id: projectId }, secret);
  console.log(token);
}
