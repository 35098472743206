import { z } from 'zod';

export const checkSubdomainRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    domain: z.string(),
  }),
});
export type CheckSubdomainRequest = z.infer<typeof checkSubdomainRequest>;
export type CheckSubdomainResponse = boolean;
