import { FC, HTMLAttributes } from 'react';

type DropdownProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const Dropdown: FC<DropdownProps> = ({
  size = 41,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_549_2137)">
          <rect
            x="5.36133"
            y="4.74976"
            width="30"
            height="30"
            rx="6"
            fill="#FAD3FB"
          />
          <rect
            x="5.86133"
            y="5.24976"
            width="29"
            height="29"
            rx="5.5"
            stroke="#E451DF"
            stroke-opacity="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.2452 23.6336C20.7571 24.1218 19.9656 24.1218 19.4774 23.6336L13.4774 17.6336C12.9893 17.1455 12.9893 16.354 13.4774 15.8659C13.9656 15.3777 14.7571 15.3777 15.2452 15.8659L20.3613 20.982L25.4774 15.8659C25.9656 15.3777 26.7571 15.3777 27.2452 15.8659C27.7334 16.354 27.7334 17.1455 27.2452 17.6336L21.2452 23.6336Z"
            fill="#C831C0"
          />
        </g>
        {shouldBlend && (
          <defs>
            <filter
              id="filter0_d_549_2137"
              x="0.361328"
              y="0.749756"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="2.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.980392 0 0 0 0 0.827451 0 0 0 0 0.984314 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_549_2137"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_549_2137"
                result="shape"
              />
            </filter>
          </defs>
        )}
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#FAD3FB"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#E451DF"
        stroke-opacity="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9347 16.3419C12.4465 16.83 11.6551 16.83 11.1669 16.3419L5.1669 10.3419C4.67874 9.85373 4.67874 9.06228 5.1669 8.57412C5.65505 8.08597 6.44651 8.08597 6.93467 8.57412L12.0508 13.6902L17.1669 8.57412C17.6551 8.08597 18.4465 8.08597 18.9347 8.57412C19.4228 9.06228 19.4228 9.85374 18.9347 10.3419L12.9347 16.3419Z"
        fill="#C831C0"
      />
    </svg>
  );
};

export default Dropdown;
