import { FC, HTMLAttributes } from 'react';

type FileUploadProps = HTMLAttributes<SVGElement> & {
  size?: number;
  shouldBlend?: boolean;
};

const FileUpload: FC<FileUploadProps> = ({
  size = 41,
  shouldBlend = false,
  ...props
}) => {
  if (shouldBlend)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_5838_19984)">
          <rect
            x="5.72235"
            y="4.375"
            width="30"
            height="30"
            rx="6"
            fill="#F7F7F7"
          />
          <rect
            x="6.22235"
            y="4.875"
            width="29"
            height="29"
            rx="5.5"
            stroke="#6A6A6F"
            stroke-opacity="0.5"
          />
          <path
            d="M12.4724 14.555V24.1951C12.4724 24.7064 12.6655 25.1968 13.0093 25.5584C13.3531 25.92 13.8195 26.1231 14.3057 26.1231H27.139C27.6252 26.1231 28.0916 25.92 28.4354 25.5584C28.7792 25.1968 28.9724 24.7064 28.9724 24.1951V19.0096L22.526 12.627L14.3057 12.627C13.8195 12.627 13.3531 12.8301 13.0093 13.1917C12.6655 13.5533 12.4724 14.0437 12.4724 14.555Z"
            fill="#6A6A6F"
            stroke="#6A6A6F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22.6942 12.627V17.2068C22.6942 17.5105 22.8264 17.8017 23.0619 18.0164C23.2974 18.2311 23.6168 18.3518 23.9498 18.3518H28.9723"
            stroke="#F7F7F7"
            stroke-width="2"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_5838_19984"
            x="0.722351"
            y="0.375"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.929412 0 0 0 0 0.929412 0 0 0 0 0.929412 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5838_19984"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5838_19984"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0507812"
        y="0.458008"
        width="24"
        height="24"
        rx="6"
        fill="#F7F7F7"
      />
      <rect
        x="0.550781"
        y="0.958008"
        width="23"
        height="23"
        rx="5.5"
        stroke="#6A6A6F"
        stroke-opacity="0.5"
      />
      <path
        d="M5.44922 8.60201V16.3141C5.44922 16.7231 5.60374 17.1155 5.8788 17.4047C6.15385 17.694 6.5269 17.8565 6.91589 17.8565H17.1826C17.5715 17.8565 17.9446 17.694 18.2196 17.4047C18.4947 17.1155 18.6492 16.7231 18.6492 16.3141V12.1657L13.4921 7.05957L6.91589 7.0596C6.5269 7.0596 6.15385 7.2221 5.8788 7.51136C5.60374 7.80062 5.44922 8.19294 5.44922 8.60201Z"
        fill="#6A6A6F"
        stroke="#6A6A6F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.625 7.05957V10.7234C13.625 10.9664 13.7308 11.1994 13.9192 11.3711C14.1076 11.5429 14.3631 11.6394 14.6295 11.6394H18.6476"
        stroke="#F7F7F7"
        stroke-width="2"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FileUpload;
