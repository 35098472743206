export const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
export const storagePath = process.env.NEXT_PUBLIC_SUPABASE_STORAGE_PATH;

export type ImageFile = File & {
  preview: string;
  error?: string;
};

export enum UploadStatus {
  Select = 'select',
  Uploading = 'uploading',
  Done = 'done',
}

export enum UploadImageType {
  FormImage = 'formImage',
  BackgroundImage = 'backgroundImage',
  Logo = 'logo',
  CoverImage = 'coverImage',
}
